import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import * as api from "../../../../../services/ApiService";
import * as tokenUtils from "../../../../../utils/tokenUtils";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { Button } from "bootstrap";
import moment from "moment";

const ReferenceForm = ({
  index,
  reference,
  handleChange,
  getReferenceStatus,
  fetchReferences,
  startRef,
  handleSubmit,
  error,
  id
}) => {
  console.log(error)
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isSubmissionFileRef = queryParams.get("ref") === "submission-file";

  const [formData, setformData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const viewRef = (refDetails) => {
    setformData(refDetails);
    setModalOpen(true);
  };
  const handleKeyPress = (event) => {
    const regex = /^[0-9]*$/; // Allow only letters and numbers
    if (!regex.test(event.key)) {
      event.preventDefault(); // Prevent invalid characters
    }
  };
console.log("index",reference)
  return (
    <div>
      <h5>
        Reference {index + 1} {"  "}
        {getReferenceStatus(reference) == "view-reference" && (
          <p className="view-reference btn btn-success btn-sm"  style={{background:"#1E88F2"}} >
          <i class="mdi mdi-history mr-1"></i> In Verification
          </p>
        )}
        {getReferenceStatus(reference) == "start-reference" && (
          <p className="start-reference btn btn-info btn-sm"onClick={() => startRef(reference)}>
            <i class="mdi mdi-minus mr-1"></i> Start Reference
          </p>
        )}
        {getReferenceStatus(reference) == "remind-reference" && (
          <p className="remind-reference btn btn-danger btn-sm" onClick={() => startRef(reference)}>
            <i class="mdi mdi-history mr-1"></i> Remind Reference
          </p>
        )}
        {getReferenceStatus(reference) == "Verified" && (
          <p className="remind-reference btn btn-success btn-sm" onClick={() => viewRef(reference)}>
          <i class="mdi mdi-check-circle mr-1"></i> Verified
          </p>
        )}
      </h5>
      <div className="row">
        <div className={`col-md-6 mb-2 ${getReferenceStatus(reference)}`}>
          <label className="form-label">Dates of Employment</label>
          <input
            id="dates_of_employment"
            type="date"
            name="dates_of_employment"
            value={reference.dates_of_employment}
            onChange={(e) => handleChange(e, index)}
            className="form-control"
            required
          />
          {
  id === index + 1 && error?.missingFields?.[0]?.key === "dates_of_employment" ? (
    <span className="error">Dates of Employment is Required</span>
  ) : id === index + 1 && error?.missingFields?.[1]?.key === "dates_of_employment" ? (
    <span className="error">Dates of Employment is Required</span>
  ) : id === index + 1 && error?.missingFields?.[2]?.key === "dates_of_employment" ? (
    <span className="error">Dates of Employment is Required</span>
  ):id === index + 1 && error?.missingFields?.[3]?.key === "dates_of_employment" ? (
    <span className="error">Dates of Employment is Required</span>
  ):id === index + 1 && error?.missingFields?.[4]?.key === "dates_of_employment" ? (
    <span className="error">Dates of Employment is Required</span>
  ) : null
}
        </div>
          

        <div className={`col-md-6 mb-2 ${getReferenceStatus(reference)}`}>
          <label className="form-label">Facility Address</label>
          <input
            type="text"
            name="address"
            value={reference.address}
            onChange={(e) =>{ handleChange(e, index)}}
            className="form-control"
            required
          />
{
  id === index + 1 && error?.missingFields?.[0]?.key === "address" ? (
    <span className="error">Dates of Employment is Required</span>
  ) : id === index + 1 && error?.missingFields?.[1]?.key === "address" ? (
    <span className="error">Dates of Employment is Required</span>
  ) : id === index + 1 && error?.missingFields?.[2]?.key === "address" ? (
    <span className="error">Dates of Employment is Required</span>
  ):id === index + 1 && error?.missingFields?.[3]?.key === "address" ? (
    <span className="error">Dates of Employment is Required</span>
  ):id === index + 1 && error?.missingFields?.[4]?.key === "address" ? (
    <span className="error">Dates of Employment is Required</span>
  ):id === index + 1 && error?.missingFields?.[5]?.key === "address" ? (
    <span className="error">Facility Address is Required</span>
  ):id === index + 1 && error?.missingFields?.[6]?.key === "address" ? (
    <span className="error">Facility Address is Required</span>
  )  : null
}
        </div>
        <div className={`col-md-6 mb-2 `}>
          <label className="form-label">Title while Employed</label>
          <input
            type="text"
            name="title_while_employed"
            value={reference.title_while_employed}
            onChange={(e) => handleChange(e, index)}
            className="form-control"
            required
          />
{
  id === index + 1 && error?.missingFields?.[0]?.key === "title_while_employed" ? (
    <span className="error">Title while Employed is Required</span>
  ) : id === index + 1 && error?.missingFields?.[1]?.key === "title_while_employed" ? (
    <span className="error">Title while Employed is Required</span>
  ) : id === index + 1 && error?.missingFields?.[2]?.key === "title_while_employed" ? (
    <span className="error">Title while Employed is Required</span>
  ):id === index + 1 && error?.missingFields?.[3]?.key === "title_while_employed" ? (
    <span className="error">Title while Employed is Required</span>
  ):id === index + 1 && error?.missingFields?.[4]?.key === "title_while_employed" ? (
    <span className="error">Title while Employed is Required</span>
  ):id === index + 1 && error?.missingFields?.[5]?.key === "title_while_employed" ? (
    <span className="error">Title while Employed is Required</span>
  )  : null
}
        </div>

       
      </div>
      <div className="row">
        <div className={`col-md-6 mb-2 ${getReferenceStatus(reference)}`}>
          <label className="form-label">Reference Title</label>
          <input
            id="title"
            type="text"
            name="title"
            value={reference.title}
            onChange={(e) => handleChange(e, index)}
            className="form-control"
            required
          />
          {id === index + 1 && error?.missingFields?.[0]?.key === "title"?<span className="error">Title is Required</span>:""}
        </div>
          

        <div className={`col-md-6 mb-2 ${getReferenceStatus(reference)}`}>
          <label className="form-label">Reference Name</label>
          <input
            type="text"
            name="name"
            value={reference.name}
            onChange={(e) =>{ handleChange(e, index)}}
            className="form-control"
            required
          />
{(id === index + 1 && error?.missingFields?.[0]?.key === "name") ? (
  <span className="error">Name is Required</span>
) : (id === index + 1 && error?.missingFields?.[1]?.key === "name") ? (
  <span className="error">Name is Required</span>
) : null}
        </div>
        <div className={`col-md-6 mb-2 `}>
          <label className="form-label">Reference Email</label>
          <input
            type="email"
            name="email"
            value={reference.email}
            onChange={(e) => handleChange(e, index)}
            className="form-control"
            required
          />
{
  id === index + 1 && error?.missingFields?.[0]?.key === "email" ? (
    <span className="error">Email is Required</span>
  ) : id === index + 1 && error?.missingFields?.[1]?.key === "email" ? (
    <span className="error">Email is Required</span>
  ) : id === index + 1 && error?.missingFields?.[2]?.key === "email" ? (
    <span className="error">Email is Required</span>
  ) : null
}
        </div>

        <div className={`col-md-6 mb-2 `}>

          <label className="form-label">Reference Phone</label>
          <input
            type="text"
            name="phone"
            onKeyPress={handleKeyPress}
            value={reference.phone}
            onChange={(e) => handleChange(e, index)}
            className="form-control"
            required
          
            // maxLength="10"
          />
          {
  id === index + 1 && error?.missingFields?.[0]?.key === "phone" ? (
    <span className="error">Phone is Required</span>
  ) : id === index + 1 && error?.missingFields?.[1]?.key === "phone" ? (
    <span className="error">Phone is Required</span>
  ) : id === index + 1 && error?.missingFields?.[2]?.key === "phone" ? (
    <span className="error">Phone is Required</span>
  ) : id === index + 1 && error?.missingFields?.[3]?.key === "phone" ? (
    <span className="error">Phone is Required</span>
  ) : null
}
        </div>
      </div>
      <div style={{display:"flex",justifyContent:"end"}}>
{/* {
  getReferenceStatus(reference) == "Verified"?""
} */}
<p className="btn btn-primary" onClick={(e)=>{handleSubmit(e,index + 1)}}>Save</p>
      </div>



      <Modal show={modalOpen} onHide={toggleModal} centered backdrop="static" size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="my-0">View Reference Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="border-bottom pb-2">Reference Completed By</h5>
          <div className="row">
            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Name</label>
                <p>{reference.details?.reference_by_name || "-"}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Title</label>
                <p>{reference.details?.reference_by_title || "-"}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Phone</label>
                <p>{reference.details?.reference_by_phone || "-"}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Email</label>
                <p>{reference.details?.reference_by_email || "-"}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Signature</label>
                <p>{reference.details?.reference_by_signature || "-"}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Date</label>
                <p>{moment(reference.details?.reference_by_signature_date).format('MM/DD/YYYY')}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Reference Information Verified By</label>
                <p>{reference.details?.reference_by_verified_by || "-"}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Date</label>
                <p>{moment(reference.details?.reference_by_verified_by_date,'YYYY/MM/DD').format('MM/DD/YYYY') || "-"}</p>
              </div>
            </div>
          </div>

          <h5 className="border-bottom pb-2">Employment Information</h5>
          <div className="row">
            <div className="col-md-4">
              <div className="mb-2">
                <label className="form-label fw-bold">Facility</label>
                <p>{reference.details?.facility || "-"}</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-2">
                <label className="form-label fw-bold">Dates of Employment</label>
                <p>{moment(reference.details?.dates_of_employment).format('MM/DD/YYYY')}</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-2">
                <label className="form-label fw-bold">Address</label>
                <p>{reference.details?.address || "-"}</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-2">
                <label className="form-label fw-bold">Title while Employed</label>
                <p>{reference.details?.title_while_employed || "-"}</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-2">
                <label className="form-label fw-bold">Phone</label>
                <p>{reference.details?.phone || "-"}</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-2">
                <label className="form-label fw-bold">Specialty Worked</label>
                <p>{reference.details?.specialty_worked || "-"}</p>
              </div>
            </div>

            <div className="col-md-12">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th></th>
                    <th className="text-center">Yes</th>
                    <th className="text-center">No</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>1. How long have you worked with this candidate?</th>
                    <td className="text-center">
                     {reference?.details?.worked_with != "null" ?(<div>{reference?.details?.worked_with} years</div>):""} 
                    </td>
                    <td className="text-center">
                    {reference?.details?.worked_with == "null" && <i class="fas fa-check"></i>}
                    </td>
                  </tr>
                  <tr>
                    <th>2. Is this person honest, reliable, and trustworthy?</th>
                    <td className="text-center">
                      {reference.details?.honest_reliable === "Yes" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.honest_reliable === "No" && <i class="fas fa-check"></i>}
                    </td>
                  </tr>
                  <tr>
                    <th>3. Was this candidate on a travel assignment?</th>
                    <td className="text-center">
                      {reference.details?.travel_assignment === "Yes" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.travel_assignment === "No" && <i class="fas fa-check"></i>}
                    </td>
                  </tr>
                  <tr>
                    <th>4. Is this candidate eligible for rehire?</th>
                    <td className="text-center">
                      {reference.details?.eligible_rehire === "Yes" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.eligible_rehire === "No" && <i class="fas fa-check"></i>}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <h5 className="border-bottom pb-2">Candidate Employment Evaluation</h5>

          <div className="row">
            <div className="col-md-12">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Criteria</th>
                    <th className="text-center">Poor</th>
                    <th className="text-center">Fair</th>
                    <th className="text-center">Average</th>
                    <th className="text-center">Good</th>
                    <th className="text-center">Excellent</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>1. Quality of Work</th>
                    <td className="text-center">
                      {reference.details?.quality_of_work === "Poor" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.quality_of_work === "Fair" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.quality_of_work === "Average" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.quality_of_work === "Good" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.quality_of_work === "Excellent" && <i class="fas fa-check"></i>}
                    </td>
                  </tr>
                  <tr>
                    <th>2. Clinical Knowledge/Skills</th>
                    <td className="text-center">
                      {reference.details?.clinical_knowledge === "Poor" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.clinical_knowledge === "Fair" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.clinical_knowledge === "Average" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.clinical_knowledge === "Good" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.clinical_knowledge === "Excellent" && <i class="fas fa-check"></i>}
                    </td>
                  </tr>
                  <tr>
                    <th>3. Attendance/Dependability</th>
                    <td className="text-center">
                      {reference.details?.attendance_dependability === "Poor" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.attendance_dependability === "Fair" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.attendance_dependability === "Average" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference.details?.attendance_dependability === "Good" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.attendance_dependability === "Excellent" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>4. Interpersonal/Communication Skills</th>
                    <td className="text-center">
                      {reference.details?.communication_skills === "Poor" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.communication_skills === "Fair" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.communication_skills === "Average" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.communication_skills === "Good" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.communication_skills === "Excellent" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>5. Relationship with Others</th>
                    <td className="text-center">
                      {reference.details?.relationship_others === "Poor" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.relationship_others === "Fair" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.relationship_others === "Average" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.relationship_others === "Good" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.relationship_others === "Excellent" && <i class="fas fa-check"></i>}
                    </td>
                  </tr>
                  <tr>
                    <th>6. Acceptance of Supervision</th>
                    <td className="text-center">
                      {reference.details?.acceptance_supervision === "Poor" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.acceptance_supervision === "Fair" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.acceptance_supervision === "Average" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference.details?.acceptance_supervision === "Good" && <i class="fas fa-check"></i>}
                    </td>
                    <td className="text-center">
                      {reference.details?.acceptance_supervision === "Excellent" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-12">
              <div className="mb-2">
                <label className="form-label fw-bold">Additional Comments</label>
                <p>{reference.details?.additional_comments || "-"}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={toggleModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReferenceForm;
