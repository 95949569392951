import React, { useState, useEffect } from "react";
import * as profile from "../../../../services/ProfileService";
import * as Yup from "yup";
import * as api from "../../../../services/ApiService";
import * as tokenUtils from "../../../../utils/tokenUtils";
import { toast } from "react-toastify";
import Spinner from "../../../Loader/Spinner";
import { Tooltip } from "react-tooltip";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import moment from "moment";

function JobPreference({
  states,
  employmentTypes,
  jobShifts,
  fetchProfileData,
}) {
  const user = tokenUtils.getTokenData();
  const [isLoading, setIsLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [showActiveStateData, setShowActiveStateData] = useState([]);
  const [activeStateData, setActiveStateData] = useState([]);
  const toggleModal = () => {
    setModalOpen(!modalOpen);

    if (!modalOpen) {
      setErrors({});
    }
  };

  const [stateModalOpen, setStateModalOpen] = useState(false);
  const [stateLicenceData, setStateLicenceData] = useState([]);
  const [getStateLicenceData, setGetStateLicenceData] = useState();
  const [formData, setformData] = useState({
    user_id: user.id,
    token: user.token,
    available_start_date: null,
    profession_id: null,
    specialty_id: null,
    searchable_profile: 1,
    employement_types: [],
    shifts: [],
    desired_state_ids: [],
    total_experience: "",
    EMR_experience: [],
    // RTO_start_date: +"00-00-0000",
    // RTO_end_date: +"00-00-0000",
    specialty_experience: 0,
    teaching_hospital_experience:"NO",
    travel_experience: 0,
    fully_vaccinated: "NO",
  });

// console.log("formData",formData)
  const toggleStateModal = () => {
    setStateModalOpen(!stateModalOpen);

    if (!stateModalOpen) {
      // Reset validation errors
      setErrors({});
    }
  };
  const [openRTOModal,setOpenRTOModal] = useState(false)
  const[rtoDate,setRtoDate] = useState()
  const toggleRTOModal = () => {
    setOpenRTOModal(!openRTOModal);

    if (!openRTOModal) {
      // Reset validation errors
      setErrors({});
    }
  };
  // console.log("formDate",formData)
 

  const [professions, setProfessions] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-professions");
        setProfessions(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [specialties, setspecialties] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchSpecialtyData = async () => {
      try {
        const apiData = await api.getMethod(
          "get-specialties/" + formData.profession_id
        );

        setspecialties(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchSpecialtyData();
  }, [formData.profession_id]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const handleChange = (e,date) => {
    if(date){
      setformData({ ...formData, available_start_date:format(e,"MM-dd-yyyy") });
    }else{
      const { name, value } = e.target;
      setformData({ ...formData, [name]: value });
  
      validateFields(name, value);
    }

  };

  const [errors, setErrors] = useState({});

  const validationSchema = Yup.object().shape({
    employement_types: Yup.array().min(
      1,
      "Select at least one employment type"
    ),
    shifts: Yup.array().min(1, "Select at least one shift"),
    desired_state_ids: Yup.array().min(1, "Select at least one state"),
    profession_id: Yup.string().required("Profession is Required"),
    specialty_id: Yup.string().required("Specialty is Required"),
    /*available_start_date: Yup.string().required("Available Start Date is Required"),*/
    total_experience: Yup.string().required("Please Select Experience"),
    // travel_experience:Yup.string().required("Please Select Experience"),
    specialty_experience:Yup.string().required("Please Select Experience"),
    teaching_hospital_experience:Yup.string().required("Please Select Experience"),
    EMR_experience:Yup.array().min(1,"Please Select Experience"),
    teaching_hospital_experience:Yup.string().required("Please Select Experience"),
    fully_vaccinated:Yup.string().required("Please Select this Feild")
  });

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const [selectAll, setSelectAll] = useState(false);

  // Handle Select All Checkbox
  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;

    if (checked) {
      // Select all state ids
      const allStateIds = states.map((state) => state.id);
      //setformData({ ...formData, desired_state_ids: allStateIds });
      setformData({ ...formData, desired_state_ids: [...allStateIds, "all"] });
      setSelectAll(true);
    } else {
      setSelectAll(false);
      // Deselect all
      setformData({ ...formData, desired_state_ids: [] });
    }
  };

  const handleEMRChange = (event) => {
    const { name, options } = event.target;
  
    // Collect all selected values
    const selectedValues = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);
  
    // Update the formData
    setformData({
      ...formData,
      [name]: selectedValues,
    });
  };
  const handleEtCheckboxChange = (event) => {
    const { name, value, checked } = event.target;

    setformData((prevValues) => {
      const newValue = parseInt(value);
      const updatedTypes = checked
        ? [...prevValues.employement_types, newValue]
        : prevValues.employement_types.filter((item) => item !== newValue);

      validateFields(name, updatedTypes);

      return {
        ...prevValues,
        employement_types: updatedTypes,
      };
    });
  };

  const handleShiftCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    const newValue = parseInt(value);
    setformData((prevValues) => {
      const updatedShifts = checked
        ? [...prevValues.shifts, newValue]
        : prevValues.shifts.filter((item) => item !== newValue);

      validateFields(name, updatedShifts);

      return {
        ...prevValues,
        shifts: updatedShifts,
      };
    });
  };

  // Handle individual select change
  const handleSelectChange = (e) => {
    const { name, value, checked } = e.target;

    const options = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );

    // Remove "all" if user deselects states individually
    const filteredOptions = options.filter((item) => item !== "all");

    setformData({ ...formData, desired_state_ids: filteredOptions });

    // Uncheck Select All if not all states are selected
    if (filteredOptions.length == states.length) {
      setSelectAll(true);
      setformData({
        ...formData,
        desired_state_ids: [...filteredOptions, "all"],
      });
    } else setSelectAll(false);
    validateFields(name, filteredOptions);
  };

  useEffect(() => {
    setSelectAll(false);
    if (
      states.length > 0 &&
      formData.desired_state_ids.length >= states.length
    ) {
      setSelectAll(true); // If all states are selected
    }
  }, [states, formData.desired_state_ids]);

  const fetchJobPreferenceData = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "user/get-job-preference");
      setformData((prevValues) => ({
        ...prevValues,
        employement_types: apiData.data.employement_types || [],
        shifts: apiData.data.shifts || [],
        desired_state_ids: apiData.data.desired_state_ids || [],
        searchable_profile: apiData.data.searchable_profile || 1,

        profession_id: apiData.data.profession_id || null,
        specialty_id: apiData.data.specialty_id || null,
        
        available_start_date: apiData.data.available_start_date || null,

        total_experience: apiData.data.total_experience,
        EMR_experience: apiData.data.EMR_experience,
        RTO_start_date: apiData.data.RTO_start_date,
        RTO_end_date: apiData.data.RTO_end_date,
        specialty_experience: apiData.data.specialty_experience,
        teaching_hospital_experience: apiData.data.teaching_hospital_experience,
        travel_experience: apiData.data.travel_experience,
        fully_vaccinated: apiData.data.fully_vaccinated,
      }));
      setShowActiveStateData(apiData.data.user_active_certificates);
      setStateLicenceData(apiData.data.user_state_license);
      setUserRtoDates(apiData.data.user_rto_dates);

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchJobPreferenceData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate all fields before submission
    let isValid = true;
    try {
      await validationSchema.validate(formData, { abortEarly: false });
    } catch (error) {
      isValid = false;
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }

    if (!isValid) return;

    try {
      const result = await api.postMethod(
        formData,
        "user/update-job-preference"
      );
      if (result) {
        toast.success(result.message);
        fetchJobPreferenceData();
        fetchProfileData();
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleActiveCertificateChange = (e,date) => {
    if(date){
      setActiveStateData({ ...activeStateData, certificate_expiry_date: e });
    }else{
      const { name, value } = e.target;
      setActiveStateData({ ...activeStateData, [name]: value });
      validateFields(name, value);
    }
   
  };
console.log("activeStateData",activeStateData)
  const handleStateLicenseChange = (e,date) => {
    if(date){
     
    setGetStateLicenceData({ ...getStateLicenceData,license_expiry_date:e})
    }
    else{
      const { name, value } = e.target;
      setGetStateLicenceData({ ...getStateLicenceData, [name]: value });
    validateFields(name, value);
    }
 
   
  };
  const handleSubmitCertificate = async () => {
    console.log()
    try {
      const payload = {
        user_id: user.id,
        type: 1,
        certificate_name: activeStateData.certificate_name,
        certificate_expiry_date:format(activeStateData.certificate_expiry_date,"MM-dd-yyyy")
      };
      console.log()
      const apiData = await api.postMethod(
        payload,
        "user/update-user-certificates-licences"
      );
      if (apiData) {
        toast.success(apiData.message);
        setShowActiveStateData(apiData.data.user_active_certificates);
        
        // fetchJobPreferenceData();
        // fetchProfileData();
        toggleModal();
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };
  const handleSubmitLicence = async () => {
    try {
      const payload = {
        user_id: user.id,
        type: 2,
        license_name: getStateLicenceData.license_name,
        location:getStateLicenceData.location,
        license_expiry_date:format(getStateLicenceData.license_expiry_date,"MM-dd-yyyy") ,
        is_compact_state_licence:getStateLicenceData.is_compact_state_licence
      };
      const apiData = await api.postMethod(
        payload,
        "user/update-user-certificates-licences"
      );
      if (apiData) {
        toast.success(apiData.message);
        setStateLicenceData(apiData.data.user_state_license);
        // fetchJobPreferenceData();
        // fetchProfileData();
        toggleStateModal();
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };
  const deleteCertificate = async(id)=>{
    try{
      const payload ={
        id:id,
        user_id:user.id,
        type:1
      }
      const apiData = await api.postMethod(
        payload,
        "user/update-user-certificates-licences"
      );
      if (apiData) {
        toast.success(apiData.message);
        fetchJobPreferenceData();
        fetchProfileData();
      }
    }
   catch(error){
    console.error("Error updating data:", error);
   }
  }
  const deleteLicence = async(id)=>{
    try{
      const payload ={
        id:id,
        user_id:user.id,
        type:2
      }
      const apiData = await api.postMethod(
        payload,
        "user/update-user-certificates-licences"
      );
      if (apiData) {
        toast.success(apiData.message);
        fetchJobPreferenceData();
        fetchProfileData();
      }
    }
   catch(error){
    console.error("Error updating data:", error);
   }
  }
  const deleteRto = async(id)=>{
    try{
      const payload ={
        id:id,
        user_id:user.id,
        type:3
      }
      const apiData = await api.postMethod(
        payload,
        "user/update-user-certificates-licences"
      );
      if (apiData) {
        toast.success(apiData.message);
        fetchJobPreferenceData();
        fetchProfileData();
      }
    }
   catch(error){
    console.error("Error updating data:", error);
   }
  }
    const handleDateChange = (date) => {
      console.log(date)
      setGetStateLicenceData({...getStateLicenceData,license_expiry_date:date});
  };
  function convertDate(inputDate) {
    let dob = inputDate && moment(inputDate).format("MM/DD/YYYY");
        if (dob == "Invalid Date") {
          if (inputDate.includes("-")) {
           return dob = new Date(inputDate.split("-").reverse().join("/"));
          } else if (inputDate.includes("/")) {
            const [day, month, year] = inputDate.split("/");
            const dateString = [month, day, year].join("/");
          return  dob = new Date(dateString);
          }
        } else {
          if (typeof new Date(inputDate) === "object") {
           return dob = new Date(inputDate);
          } else {
           return dob = inputDate;
          }
        }
        return dob
  }

  const [count, setCount] = useState(1); // useState returns a pair. 'count' is the current state. 'setCount' is a function we can use to update the state.
  function increment() {
    //setCount(prevCount => prevCount+=1);
    setCount(function (prevCount) {
      return (prevCount += 1);
    });
  }

  function decrement() {
    setCount(function (prevCount) {
      if (prevCount > 1) {
        return (prevCount -= 1);
      } else {
        return (prevCount = 1);
      }
    });
  }

  const handleRtoDate = (e,type) => {
    if(type == "start"){
     
      setRtoDate({ ...rtoDate,start_date:e})
    }
    if (type == "end"){
      setRtoDate({ ...rtoDate,end_date:e})
    }
 
   
  };
const [userRtoDates,setUserRtoDates] = useState()
  const handleRtoDateSubmit = async()=>{
    try {
      const payload = {
        user_id: user.id,
        type: 3,
        start_date:format( rtoDate.start_date,"MM-dd-yyyy"),
        end_date: format(rtoDate.end_date,"MM-dd-yyyy"),
      };
      const apiData = await api.postMethod(
        payload,
        "user/update-user-certificates-licences"
      );
      if (apiData) {
        toast.success(apiData.message);
        setUserRtoDates(apiData.data.user_rto_dates);
        toggleRTOModal();
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="form-group mb-3 col-md-4">
            <label htmlFor="profession_id" className="form-label">
              Profession *
            </label>
            <select
              id="profession_id"
              className="form-control"
              name="profession_id"
              value={
                formData.profession_id !== null ? formData.profession_id : ""
              }
              onChange={handleChange}
            >
              <option value="" key="">
                Please Select
              </option>
              {/* Render the professions */}
              {professions.map((profession) => (
                <option key={profession.id} value={profession.id}>
                  {profession.profession}
                </option>
              ))}
            </select>
            {errors.profession_id && (
              <span className="error">{errors.profession_id}</span>
            )}
          </div>
          <div className="form-group mb-3 col-md-4">
            <label htmlFor="specialty_id" className="form-label">
              Specialty *
            </label>
            <select
              id="specialty_id"
              className="form-control"
              name="specialty_id"
              value={
                formData.specialty_id !== null ? formData.specialty_id : ""
              }
              onChange={handleChange}
            >
              <option value="" key="">
                Please Select
              </option>

              {/* Render the specialties */}
              {specialties.map((specialty) => (
                <option key={specialty.id} value={specialty.id}>
                  {specialty.specialty}
                </option>
              ))}
            </select>
            {errors.specialty_id && (
              <span className="error">{errors.specialty_id}</span>
            )}
          </div>

          <div className="col-md-4">
            <div className="mb-0">
              <label htmlFor="available_start_date" className="form-label">
                Available Start Date *
              </label>
              {/* <DatePicker
                  type="date"
                  className="form-control"
                  id="available_start_date"
                  name="available_start_date"
                  required
                  onChange={(e)=>handleChange(e,"date")}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                  selected={formData.available_start_date}
                //  value={formData.available_start_date}
                  // defaultValue={formData.school_college}
                /> */}
                <div  style={{width:"10vw"}}  >
                 {/* <DatePicker
                    required
                     className="form-control"
                    style={{width:"10px"}}
                    placeholderText="DD/MM/YYYY"
                    dateFormat="MM/dd/yyyy"
                    name="available_start_date"
                    onChange={(e) => handleChange(e, "data")}
                    value={convertDate(formData.available_start_date)}
                    selected={convertDate(formData.available_start_date)}
                  /> */}
                  <DatePicker
                  type="date"
                  className="form-control"
                  id="available_start_date"
                  name="available_start_date"
                  required
                  // onChange={(e)=>handleActiveCertificateChange(e,"date")}
                  
                  onChange={(e) => handleChange(e, "data")}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                    value={convertDate(formData?.available_start_date)}
                    selected={formData?.available_start_date&&convertDate(formData?.available_start_date)}
                  // defaultValue={formData.school_college}
                />
                  </div>
              {/* <input
                className="form-control"
                type="date"
                name="available_start_date"
                id="available_start_date"
                value={formData.available_start_date}
                // defaultValue={
                //   formData.available_start_date !== null
                //     ? formData.available_start_date
                //     : ""
                // }
                onChange={handleChange}
              /> */}
              {/*errors.available_start_date && (
              <span className="error">{errors.available_start_date}</span>
            )*/}
            </div>
          </div>

          <div className="col-md-4">
            <div className="mb-3">
              <label htmlFor="total_experience" className="form-label">
              Total Years of Experience in Profession *
              </label>
              <select
                className="form-control"
                id="total_experience"
                name="total_experience"
                onChange={handleChange}
                value={
                  formData.total_experience != null
                    ? formData.total_experience
                    : ""
                }
              >
                <option value="" key="">
                  0 Year(s)
                </option>
                {Array.from(Array(15), (e, i) => {
                  return (
                    <option value={i + 1} key={i + 1}>
                      {i + 1} Year(s)
                    </option>
                  );
                })}
                <option value="15+">15+ Year(s)</option>
              </select>
              {errors.total_experience && (
                <span className="error">{errors.total_experience}</span>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="mb-3">
              <label htmlFor="specialty_experience" className="form-label">
                Total Years in Specialty *
              </label>
              <select
                className="form-control"
                id="specialty_experience"
                name="specialty_experience"
                onChange={handleChange}
                value={
                  formData.specialty_experience !== null
                    ? formData.specialty_experience
                    : ""
                }
              >
                <option value="" key="">
                  0 Year(s)
                </option>
                {Array.from(Array(15), (e, i) => {
                  return (
                    <option value={i + 1} key={i + 1}>
                      {i + 1} Year(s)
                    </option>
                  );
                })}
                <option value="15+">15+ Year(s)</option>
              </select>
              {errors.specialty_experience && (
                <span className="error">{errors.specialty_experience}</span>
              )}
            </div>
          </div>
          {/* <div className="col-md-4  " onClick={openRTOModal}> */}
            {/* RTO * */}
            {/* <div className="row mt-2"> */}
            {/* {[...Array(count)].map((_, index) => <>
              <div className="mb-0 w-50">
                <label htmlFor="RTO_start_date" className="form-label">
                  Start Date
                </label> */}
                {/* <input
                  className="form-control"
                  type="date"
                  name="RTO_start_date"
                  id="RTO_start_date"
                  value={formData.RTO_start_date[index]?formData.RTO_start_date:""}
                  // defaultValue={
                  //   formData.RTO_start_date !== null
                  //     ? formData.RTO_start_date
                  //     : ""
                  // }
                  onChange={(e)=>handleChangeDate(e,index,"start")}
                /> */}
                {/* <DatePicker
                  type="date"
                  className="form-control"
                 name={ `RTO_start_date [${index}]`  }
                 id={ `RTO_start_date [${index}]`  }
                  required
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                  onChange={(e)=>handleChangeDate(e,index,"start")}
                  value={formData.RTO_start_date[index]?formData.RTO_start_date:""}
                  // selected={convertDate(formData.RTO_start_date?formData?.RTO_start_date[index]:"")}
                  // selected={formData.RTO_start_date}

                  // defaultValue={formData.school_college}
                /> */}
                {/* <DatePicker
                    required
                    style={{width:"10px"}}
                    placeholderText="MM/DD/YYYY"
                     className="form-control"
                    dateFormat="MM/dd/yyyy"
                    name={ `RTO_start_date [${index}]`  }
                    onChange={(e) => handleChangeDate(e,index,"end")}
                    value={convertDate(formData.RTO_start_date[index]?formData.RTO_end_date:"")}
                    selected={convertDate(formData.RTO_start_date[index])}
                    selected={convertDate(formData.RTO_end_date[index])}
                  /> */}
                {/*errors.available_start_date && (
              <span className="error">{errors.available_start_date}</span>
            )*/}
              {/* </div> */}
              {/* <div className="mb-0 w-50">
                <label htmlFor="RTO_end_date" className="form-label">
                  End Date 
                </label> */}
                {/* <input
                  className="form-control"
                  type="date"
                  name={`RTO_end_date [${index}]`}
                  id="RTO_end_date"
                  value={formData.RTO_end_date[index]}
                  // defaultValue={
                  //   formData.RTO_end_date !== null ? formData.RTO_end_date : ""
                  // }
                  onChange={(e)=>handleChangeDate(e,index,"end")}
                /> */}
                {/* <DatePicker
                  type="date"
                  className="form-control"
                 name={ `RTO_end_date [${index}]`  }
                 id={ `RTO_end_date [${index}]`  }
                  required
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                  onChange={(e)=>handleChangeDate(e,index,"end")}
                  value={convertDate(formData.RTO_end_date[index]?formData.RTO_end_date:"")}
                  // selected={convertDate(formData.RTO_start_date?formData?.RTO_start_date[index]:"")}
                  // selected={formData.RTO_end_date[index]}

                  // defaultValue={formData.school_college}
                /> */}
                {/* <DatePicker
                    required
                    style={{width:"10px"}}
                    placeholderText="MM/DD/YYYY"
                     className="form-control"
                    dateFormat="MM/dd/yyyy"
                    name={ `RTO_end_date [${index}]`  }
                    onChange={(e) => handleChangeDate(e,index,"end")}
                    value={convertDate(formData.RTO_end_date[index]?formData.RTO_end_date:"")}
                    selected={convertDate(formData.RTO_end_date)}
                    selected={convertDate(formData.RTO_end_date[index])}
                  /> */}
                {/*errors.available_start_date && (
              <span className="error">{errors.available_start_date}</span>
            )*/}
              {/* </div> */}
            {/* </>)} */}
              
            {/* </div> */}
          {/* </div> */}
          
          <div className="col-md-4">
            <div className="mb-3">
              <label
                htmlFor="teaching_hospital_experience"
                className="form-label"
              >
                Teaching Hospital Experience *
              </label>
              <select
                className="form-control"
                id="teaching_hospital_experience"
                name="teaching_hospital_experience"
                onChange={handleChange}
                value={
                  formData.teaching_hospital_experience !== null
                    ? formData.teaching_hospital_experience
                    : ""
                }
              >
               <option value="" > Please Select  </option>
              <option value="Not Sure">Not Sure</option>
                <option value="NO" key="">
                  NO
                </option>
                <option value="YES">YES</option>
              </select>
              {errors.teaching_hospital_experience && (
                <span className="error">{errors.teaching_hospital_experience}</span>
              )}
            </div>
          </div>
          <div className="col-md-4 mt-1">
            <div className="mb-3">
              <label htmlFor="travel_experience" className="form-label">
                Travel Experience
              </label>
              <select
                className="form-control"
                id="travel_experience"
                name="travel_experience"
                onChange={handleChange}
                value={
                  formData.travel_experience !== null
                    ? formData.travel_experience
                    : ""
                }
              >
                <option value="" key="">
                  0 Year(s)
                </option>
                {Array.from(Array(15), (e, i) => {
                  return (
                    <option value={i + 1} key={i + 1}>
                      {i + 1} Year(s)
                    </option>
                  );
                })}
                <option value="15+">15+ Year(s)</option>
              </select>
              {/* {errors.travel_experience && (
                <span className="error">{errors.travel_experience}</span>
              )} */}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3">
              <label htmlFor="fully_vaccinated" className="form-label">
                Fully Vaccinated *
              </label>
              <select
                className="form-control"
                id="fully_vaccinated"
                name="fully_vaccinated"
                onChange={handleChange}
                value={
                  formData.fully_vaccinated !== null
                    ? formData.fully_vaccinated
                    : ""
                }
              >
               <option value=""> Please Select  </option>
                <option value="NO" key="">
                  NO
                </option>

                <option value="YES">YES</option>
              </select>
              {errors.fully_vaccinated && (
                <span className="error">{errors.fully_vaccinated}</span>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-2">
              <label htmlFor="EMR_experience" className="form-label">
                EMR Experience *
              </label>
              <br />
                <small className="text-muted">
                  (Ctrl+Click to Select Multiple)
                </small>
              <select
                className="form-control"
                name="EMR_experience"
                id="EMR_experience"
                onChange={handleEMRChange}
                multiple
                value={formData.EMR_experience || []}
                // placeholder="Please Select"
              >
               <option value="" > Please Select  </option>
               <option value="All Scripts">All Scripts</option>
               <option  value="Cerner">Cerner</option>
               <option  value="Centricity">Centricity</option>
               <option  value="Epic">Epic</option>
               <option  value="GE Healthcare">GE Healthcare</option>
               <option  value="Health Connect">Health Connect</option>
               <option  value="Horizon">Horizon</option>
               <option  value="McKesson">McKesson</option>
               <option  value="Meditech">Meditech</option>
               <option  value="Siemens">Siemens</option>
               <option  value="Sunrise">Sunrise</option>
              </select>
              <span className="error">{errors.EMR_experience}</span>
              {/* <input
                className="form-control"
                type="input"
                name="EMR_experience"
                id="EMR_experience"
                placeholder="Experience"
                value={formData.EMR_experience !== null
                    ? formData.EMR_experience
                    : ""}
                // defaultValue={
                //   
                // }
                onChange={handleChange}
              /> */}
              {/*errors.available_start_date && (
              <span className="error">{errors.available_start_date}</span>
            )*/}
            </div>
          </div>
          <div className="col-md-2">
            <div className="mb-0">
              <label htmlFor="employment_type_id" className="form-label">
                Employment Type *
              </label>
              <br />
              {employmentTypes &&
                employmentTypes.length > 0 &&
                employmentTypes.map((etype) => (
                  <label
                    key={etype.id}
                    htmlFor={`et_${etype.id}`}
                    className="me-2 d-block"
                  >
                    <input
                      type="checkbox"
                      name="employement_types"
                      value={etype.id}
                      id={`et_${etype.id}`}
                      onChange={handleEtCheckboxChange}
                      checked={formData.employement_types.includes(etype.id)}
                    />{" "}
                    {etype.title}
                  </label>
                ))}
              {errors.employement_types && (
                <span className="error">{errors.employement_types}</span>
              )}
            </div>
          </div>

          <div className="col-md-2">
            <div className="mb-0">
              <label htmlFor="shift" className="form-label">
                Shift *
              </label>
              <br />
              {jobShifts &&
                jobShifts.length > 0 &&
                jobShifts.map((shift) => (
                  <label
                    key={shift.id}
                    htmlFor={`shift_${shift.id}`}
                    className="me-2 d-block"
                  >
                    <input
                      type="checkbox"
                      name="shifts"
                      value={shift.id}
                      id={`shift_${shift.id}`}
                      onChange={handleShiftCheckboxChange}
                      checked={formData.shifts.includes(shift.id)}
                    />{" "}
                    {shift.title}
                  </label>
                ))}
              {errors.shifts && <span className="error">{errors.shifts}</span>}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-2">
              <label htmlFor="desired_state_ids" className="form-label w-100">
                Where would you like to work? *
                <br />
                <small className="text-muted">
                  (Ctrl+Click to Select Multiple)
                </small>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                  className="float-end mt-n2"
                  data-tooltip-id="mt-3"
                  data-tooltip-content={
                    selectAll ? "Unselect All" : "Select All"
                  }
                />
                <Tooltip id="mt-3"></Tooltip>
              </label>

              <select
                className="form-control"
                name="desired_state_ids"
                id="desired_state_ids"
                multiple
                value={
                  formData.desired_state_ids !== null
                    ? formData.desired_state_ids
                    : []
                }
                onChange={handleSelectChange}
              >
                {states &&
                  states.length > 0 &&
                  states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
              </select>
              {errors.desired_state_ids && (
                <span className="error">{errors.desired_state_ids}</span>
              )}
            </div>
          </div>
          <div className="col-md-4 mt-1 p-3 pt-0  ">
            <div className="row w-75 ml-2">
              <Button
                className="btn btn-info float-start mb-1"
                onClick={toggleModal}
              >
                <i className="fa fa-plus"></i> Add Active Certifications
              </Button>

              {showActiveStateData &&
                showActiveStateData.map((data) => (
                  <div style={{display:"flex", justifyContent:'space-between'}}>
                    <p>
                      {data.certificate_name} | {moment(data.certificate_expiry_date).format('MM/DD/YYYY')}
                    </p>
                    <i class="bi bi-trash" onClick={()=>deleteCertificate(data.id)}></i>
                  </div>
                ))}
            </div>
          </div>
          <div className="col-md-4 mt-1 ">
            <div className="row w-75">
              <Button
                className="btn btn-info float-start mb-1"
                onClick={toggleStateModal}
              >
                <i className="fa fa-plus"></i> Add State License
              </Button>
              {stateLicenceData &&
                stateLicenceData.map((data) => (
                  <div style={{display:"flex", justifyContent:'space-between'}}>
                    <p>
                      {data.license_name} | {data.location} |{" "}
                      {moment(data.license_expiry_date).format('MM/DD/YYYY')}
                    </p>
                    <i class="bi bi-trash" onClick={()=>deleteLicence(data.id)}></i>
                  </div>
                ))}
            </div>
           
          </div>
          <div className="col-md-4 mt-1 ">
            <div className="row w-75">
          <Button
                className="btn btn-info float-start mb-1"
                onClick={toggleRTOModal}
              >
                <i className="fa fa-plus"></i> Add RTO
              </Button>
             
              </div>
              {
                userRtoDates&&userRtoDates.map((data)=> <>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <p>Start Date : {data.rto_start_date}  &nbsp;|  &nbsp;
                    End Date : {data.rto_end_date}</p>
                    <i class="bi bi-trash" onClick={()=>deleteRto(data.id)}></i>
                    </div>
                  </>
                )
              }
              </div>
        </div>

        <div className="text-end">
          <button
            type="submit"
            className="btn btn-primary waves-effect waves-light "
          >
            Save
          </button>
        </div>

        {isLoading === true && <Spinner />}
      </form>
      <Modal show={modalOpen} onHide={toggleModal} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Add Active Certifications</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-2">
                <label htmlFor="degree" className="form-label">
                Certification Name 
                </label>
                <select
                className="form-control"
                name="certificate_name"
                id="certificate_name"
                onChange={handleActiveCertificateChange}
                placeholder="Please Select"
              >
               <option value="" > Please Select</option>
               <option value="BLS/CPR">BLS/CPR</option>
               <option value="ACLS">ACLS</option>
               <option value="PALS">PALS</option>
               <option value="NRP">NRP</option>
               <option value="TNCC">TNCC</option>
               <option value="ENPC">ENPC</option>
               <option value="CEN">CEN</option>
               <option value="CCRN">CCRN</option>
               <option value="NIHSS ">NIHSS </option>

              </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-2">
                <label htmlFor="school_college" className="form-label">
                  Expiry Date
                </label>
                <div>
                <DatePicker
                  type="date"
                  className="form-control"
                  id="certificate_expiry_date"
                  name="certificate_expiry_date"
                  required
                  onChange={(e)=>handleActiveCertificateChange(e,"date")}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                  selected={activeStateData?.certificate_expiry_date&&convertDate(activeStateData?.certificate_expiry_date)}
                  // defaultValue={formData.school_college}
                />
                </div>
                
                {/* <input
                  type="date"
                  className="form-control"
                  id="certificate_expiry_date"
                  name="certificate_expiry_date"
                  required
                  onChange={handleActiveCertificateChange}
                  // defaultValue={formData.school_college}
                /> */}
                {/* {errors.school_college && <span className="error">{errors.school_college}</span>} */}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            className="btn btn-secondary"
            onClick={toggleModal}
          >
            Close
          </Button>
          <Button
            className="btn btn-primary"
            onClick={() => handleSubmitCertificate()}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={stateModalOpen}
        onHide={toggleStateModal}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Add State License</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-2">
                <label htmlFor="is_compact_state_licence" className="form-label">
                Is this a Compact State License? 
                </label>
                <select
                className="form-control"
                name="is_compact_state_licence"
                id="is_compact_state_licence"
                onChange={handleStateLicenseChange}
                // placeholder="Please Select"
              >
               <option value="" > Please Select </option>
               <option>Yes</option>
               <option>No</option>
              </select>
               
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-2">
                <label htmlFor="license_name" className="form-label">
                  License Type
                </label>
                <select
                className="form-control"
                name="license_name"
                id="license_name"
                onChange={handleStateLicenseChange}
                // placeholder="Please Select"
              >
               <option value="" > Please Select  </option>
               <option value="RN">RN</option>
               <option  value="LPN/LVN">LPN/LVN</option>
               <option  value="CNA">CNA</option>
              </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-2">
                <label htmlFor="location" className="form-label">
                License State
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="location"
                  name="location"
                  required
                  onChange={handleStateLicenseChange}
                  // defaultValue={formData.location}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-2">
                <label htmlFor="license_expiry_date" 
                className="form-label">
                  Expiry Date
                </label>
                <div>
                <DatePicker
                  type="date"
                  className="form-control"
                  id="license_expiry_date"
                  name="license_expiry_date"
                  required
                  onChange={(e)=>handleStateLicenseChange(e,"date")}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                  selected={getStateLicenceData?.license_expiry_date}
                  //  format="MM/dd/yyyy"
                  // defaultValue={formData.school_college}
                />
                </div>
                
                     {/* <input
                  type="date"
                  className="form-control"
                  id="license_expiry_date"
                  name="license_expiry_date"
                  required
                  onChange={(e)=>handleStateLicenseChange(e)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                  // defaultValue={formData.school_college}
                /> */}
                {/* {errors.school_college && <span className="error">{errors.school_college}</span>} */}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-secondary" onClick={toggleStateModal}>
            Close
          </Button>
          <Button className="btn btn-primary" onClick={()=>handleSubmitLicence()}>Submit</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={openRTOModal}
        onHide={toggleRTOModal}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Add RTO</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            
            <div className="col-md-12 ">
              <div className="mb-2">
                <label htmlFor="start_date" 
                className="form-label">
                  Start Date
                </label>
                <div>
                <DatePicker
                  type="date"
                  className="form-control"
                  id="start_date"
                  name="start_date"
                  required
                  onChange={(e)=>handleRtoDate(e,"start")}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                  selected={rtoDate?.start_date}
                  //  format="MM/dd/yyyy"
                  // defaultValue={formData.school_college}
                />
                </div>
                
                     {/* <input
                  type="date"
                  className="form-control"
                  id="license_expiry_date"
                  name="license_expiry_date"
                  required
                  onChange={(e)=>handleStateLicenseChange(e)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                  // defaultValue={formData.school_college}
                /> */}
                {/* {errors.school_college && <span className="error">{errors.school_college}</span>} */}
              </div>
              <div className="mb-2">
                <label htmlFor="end_date" 
                className="form-label">
                  End Date
                </label>
                <div>
                <DatePicker
                  type="date"
                  className="form-control"
                  id="end_Date"
                  name="end_Date"
                  required
                  onChange={(e)=>handleRtoDate(e,"end")}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                  selected={rtoDate?.end_date}
                  //  format="MM/dd/yyyy"
                  // defaultValue={formData.school_college}
                />
                </div>

              </div>
            </div>
            <div className="col-md-12">
              
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-secondary" onClick={toggleRTOModal}>
            Close
          </Button>
          <Button className="btn btn-primary" onClick={()=>handleRtoDateSubmit()}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default JobPreference;
