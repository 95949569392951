import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios"; // Or any other library you use for making API calls
import ReferenceForm from "../Tabs/Components/ReferenceForm";
import * as Yup from "yup";
import * as api from "../../../../services/ApiService";
import * as tokenUtils from "../../../../utils/tokenUtils";
import { phoneRegExp } from "../../../../utils/dateFormat";
import { toast } from "react-toastify";
import usePrompt from "../../../../hooks/usePrompt";
import moment from "moment";

const ReferencesPage = ({ refreshPage }) => {
  let user = tokenUtils.getTokenData();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [error,setError] = useState("")
  const [id,setId] = useState("")
  const isSubmissionFileRef = queryParams.get("ref") === "submission-file";
// const [id,setId] = useState()
  const initialReferences = [
    {dates_of_employment:"",title_while_employed:"", address:"",title: "", name: "", email: "", phone: "", started: false, completed: false, applied: false },
    {dates_of_employment:"",title_while_employed:"",address:"", title: "", name: "", email: "", phone: "", started: false, completed: false, applied: false },
    {dates_of_employment:"",title_while_employed:"",address:"", title: "", name: "", email: "", phone: "", started: false, completed: false, applied: false },
  ];

  const [data, setData] = useState({
    user_id: user.id,
    token: user.token,
    references: initialReferences,
  });
console.log("data",data)
  const [loading, setLoading] = useState(true);

  // Fetch data from API when component mounts
  const fetchReferences = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "user/get-references");
      let references = apiData.data;
      // Ensure there are always three references
      if (references.length < 3) {
        references = [
          ...references,
          ...Array(3 - references.length)
            .fill()
            .map(() => ({
              title: "",
              name: "",
              email: "",
              phone: "",
              dates_of_employment:"",
              title_while_employed:"",
              address:"",
              started: false,
              completed: false,
              applied: false,
            })),
        ];
      }

      setData((prevData) => ({
        ...prevData,
        references,
      }));
    } catch (error) {
      console.error("Error fetching references:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReferences();
  }, []);

  const { confirm } = usePrompt();

  const startRef = async (record) => {
    console.log("record",record)
    const confirmed = await confirm({title:`You are agreeing to send a reference form to be completed?`});
    console.log(confirmed)
    if (confirmed.isConfirmed) {
      try {
        const param = {
          // dates_of_employment:moment(user.dates_of_employment).format("MM/DD/YYYY"),
          // address:user.address,
          // title_while_employed:user.title_while_employed,
          user_id: user.id,
          id: record.id,
          name: record.name,
          email: record.email,
          title: record.title,
          phone: record.phone,
          sender_name: user.name,
        };

        var result = await api.postMethod(param, "user/send-reference-request");
        if (result.status) {
          toast.success(result.message);
          // refreshPage();
          fetchReferences();
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const newReferences = [...data.references];
    newReferences[index][name] = value;
    setData((prevData) => ({
      ...prevData,
      references: newReferences,
    }));

    // Check if all fields are filled for the reference
    const allFieldsFilled =
    newReferences[index].dates_of_employment&&
    newReferences[index].title_while_employed &&
    newReferences[index].address &&
      newReferences[index].title &&
      newReferences[index].name &&
      newReferences[index].email &&
      newReferences[index].phone;

    // Send email if all fields are filled
    if (allFieldsFilled && !newReferences[index].started) {
      // startRef(newReferences[index]); // Initiates reference request
      newReferences[index].started = true;
    }
  };

  const handleSubmit = async (e,id) => {
    e.preventDefault();
    setLoading(true);
    // console.log("id",{...data.references[id-1]})
    try {
      const referenceData = data.references[id - 1];

      // Check for undefined or empty fields
      const missingFields = [];
      for (const [key, value] of Object.entries(referenceData)) {
        if (value === undefined || value === "") {

          missingFields.push({key:key});
        }
      }
      console.log("missingFields",missingFields)
      setError({missingFields})
      setId(id)
      const param ={
        ...data,
        reference_no:id
      }
      console.log("data",data)
     if(missingFields.length==0){
      const result = await api.postMethod(param, "user/update-references");
      if (result.status) {
        toast.success(result.message);
        // console.log("result",result)
        startRef(result.data)
        // refreshPage();
        fetchReferences();
        setLoading(false);
      } else {
        setLoading(false);
      }
     }   
    } catch (error) {
      setLoading(false);
    }
  };

  // Add logic to determine the reference state
  const getReferenceStatus = (reference) => {
    // console.log("reference",reference)
  
      if (!reference.title || !reference.name || !reference.email || !reference.phone) {
        return ""; // No action if fields are incomplete
      }
      if (reference.is_verify == 1) return "Verified";
      if (reference.completed &&reference.is_verify == 0) return "view-reference";
      if (reference.applied) return "remind-reference";
      if (reference.started) return "start-reference";
      return "";  
   
  };


  return (
    <form>
      {data.references.map((reference, index) => (
        <ReferenceForm
          key={index}
          index={index}
          reference={reference}
          handleChange={handleChange}
          getReferenceStatus={getReferenceStatus}
          fetchReferences={fetchReferences}
          startRef={startRef}
          handleSubmit={handleSubmit}
          error={error}
          id={id}
          // setId={setId}
        />
      ))}
      <div className="text-end">
        {/* <button type="submit" className="btn btn-primary">
          Submit
        </button> */}
      </div>
    </form>
  );
};

export default ReferencesPage;
