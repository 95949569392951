import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../../Layout/Header";
import Footer from "../../../Layout/Footer";
import Sidebar from "../../../Layout/Sidebar";
import ThemeSettings from "../../../Layout/ThemeSettings";
import * as api from "../../../../services/ApiService";
import * as Yup from "yup";
import * as tokenUtils from "../../../../utils/tokenUtils";
import Spinner from "../../../Loader/Spinner";
import PageTitle from "../../../Layout/PageTitle";
import { currentRole } from "../../../Layout/HelmetComponent";

function JobPostStep1() {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { jobID } = useParams();

  // Get data from token util
  let user = tokenUtils.getTokenData();

  const generateJobId = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const length = 8; // You can adjust the length of the job ID as needed
    let jobId = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      jobId += characters.charAt(randomIndex);
    }

    return jobId;
  };

  const [formData, setformData] = useState({
    step: "step1",
    id: null,
    user_id: user.id,
    token: user.token,
    title: null,
    unique_id: null,
    profession_id: null,
    specialty_id: null,
    start_date: null,
    end_date: null,
    shift_id: null,
    total_opening: null,
    is_urgent: 0,
  });

  const fetchDratJobs = async () => {
    try {
      setIsLoading(true);
      const param = {
        step: "step1",
        user_id: user.id,
        token: user.token,
        jobID: jobID,
      };
      const apiData = await api.postMethod(param, "admin/get-draft-job");
      //const apiDataObj = Object.setPrototypeOf(apiData, Object.prototype);
      if (apiData.status) {
        // Save the data in local storage
        //localStorage.setItem("job_id", apiData.data.id);
        //navigate("/admin/job-post-step2");
        setformData(apiData.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
      /*
      const apiDataObj = apiData.reduce((acc, currentValue, index) => {
        acc[index] = currentValue;
        return acc;
      }, {});
      */

      //setWorkHisstories(apiDataObj);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if(jobID){
      fetchDratJobs();
    }
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  //Define the validation schema
  const validationSchema = Yup.object({
    title: Yup.string().required("Job Posting Title is Required"),
    /*unique_id: Yup.string().required("Job ID is Required"),*/
    profession_id: Yup.string().required("Profession is Required"),
    /*specialty_id: Yup.string().required("Specialty is Required"),*/
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  useEffect(() => {
    if (!formData.unique_id) {
      setformData((prevData) => ({
        ...prevData,
        unique_id: generateJobId(),
      }));
    }
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setIsLoading(true);
      const result = await api.postMethod(formData, "admin/post-job");
      if (result) {
        setIsLoading(false);
        // Save the data in local storage
        localStorage.setItem("job_id", result.data.job_id);
        if (jobID) navigate("/admin/job-post-step2/" + jobID);
        else navigate("/admin/job-post-step2");
      }
    } catch (error) {
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const [professions, setProfessions] = useState([]);
  const [shifts, setShifts] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-shifts");
        setShifts(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-professions");
        setProfessions(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [specialties, setspecialties] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchSpecialtyData = async () => {
      try {
        const apiData = await api.getMethod("get-specialties/" + formData.profession_id);

        setspecialties(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchSpecialtyData();
  }, [formData.profession_id]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const breadcrumbItems = [
    { text: "Admin Dashboard", link: "/admin/dashboard" },
    { text: "Jobs", link: "/admin/job-postings" },
    { text: "Post a Job", link: null },
  ];

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              <PageTitle title="Post a Job" breadcrumbItems={breadcrumbItems} />
              {/*Job progress bar start*/}
              <div className="row">
                <div className="col-12">
                  <div className="stepper-wrapper">
                    <div className="stepper-item active">
                      <div className="step-counter">1</div>
                      <div className="step-name">Position Details</div>
                    </div>
                    <div className="stepper-item">
                      <div className="step-counter">2</div>
                      <div className="step-name">Location &amp; Salary</div>
                    </div>
                    <div className="stepper-item">
                      <div className="step-counter">3</div>
                      <div className="step-name">Qualification &amp; Responsibilities</div>
                    </div>
                    {/* <div className="stepper-item">
                      <div className="step-counter">4</div>
                      <div className="step-name">Attatchments</div>
                    </div> */}
                  </div>
                </div>
              </div>
              {/*Job progress bar end*/}
              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <h4 className="header-title text-uppercase mb-2">Position Detail</h4>
                      <p className="sub-header">
                        Add crucial specifics regarding your position, like Job Title, Category,
                        Department, and Location.
                      </p>
                      <form onSubmit={handleFormSubmit}>
                        <div className="row">
                          <div className="form-group mb-3 col-md-8">
                            <label htmlFor="title">Job Posting Title *</label>
                            <input
                              type="text"
                              className="form-control"
                              id="title"
                              name="title"
                              defaultValue={formData.title}
                              onChange={handleChange}
                            />
                            {errors.title && <span className="error">{errors.title}</span>}
                          </div>

                          <div className="form-group mb-3 col-md-4">
                            <label htmlFor="unique_id">Job ID</label>
                            <input
                              type="text"
                              className="form-control"
                              id="unique_id"
                              name="unique_id"
                              value={formData.unique_id}
                              onChange={handleChange}
                              readOnly
                            />
                            {errors.unique_id && <span className="error">{errors.unique_id}</span>}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group mb-3 col-md-3">
                            <label htmlFor="profession_id">Profession *</label>
                            <select
                              id="profession_id"
                              className="form-control"
                              name="profession_id"
                              value={formData.profession_id !== null ? formData.profession_id : ""}
                              onChange={handleChange}
                            >
                              <option value="" key="">
                                Please Select
                              </option>
                              {/* Render the professions */}
                              {professions.map((profession) => (
                                <option key={profession.id} value={profession.id}>
                                  {profession.profession}
                                </option>
                              ))}
                            </select>
                            {errors.profession_id && (
                              <span className="error">{errors.profession_id}</span>
                            )}
                          </div>
                          <div className="form-group mb-3 col-md-3">
                            <label htmlFor="specialty_id">Specialty</label>
                            <select
                              id="specialty_id"
                              className="form-control"
                              name="specialty_id"
                              value={formData.specialty_id !== null ? formData.specialty_id : ""}
                              onChange={handleChange}
                            >
                              <option value="" key="">
                                Please Select
                              </option>

                              {/* Render the specialties */}
                              {specialties.map((specialty) => (
                                <option key={specialty.id} value={specialty.id}>
                                  {specialty.specialty}
                                </option>
                              ))}
                            </select>
                            {/* errors.specialty_id && (
                              <span className="error">{errors.specialty_id}</span>
                            ) */}
                          </div>

                          <div className="form-group mb-3 col-md-3">
                            <label htmlFor="start_date">Start Date</label>
                            <input
                              type="date"
                              className="form-control"
                              id="start_date"
                              name="start_date"
                              defaultValue={formData.start_date}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group mb-3 col-md-3">
                            <label htmlFor="end_date">End Date</label>
                            <input
                              type="date"
                              className="form-control"
                              id="end_date"
                              name="end_date"
                              defaultValue={formData.end_date}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group mb-3 col-md-3">
                            <label htmlFor="shift_id">Shift</label>
                            <select
                              className="form-control"
                              id="shift_id"
                              name="shift_id"
                              value={formData.shift_id !== null ? formData.shift_id : ""}
                              onChange={handleChange}
                            >
                              <option value="" key="">
                                -
                              </option>
                              {/* Render the employment types */}
                              {shifts.map((shift) => (
                                <option key={shift.id} value={shift.id}>
                                  {shift.title}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group mb-3 col-md-3">
                            <label htmlFor="total_opening">Total Openings</label>
                            <input
                              type="number"
                              className="form-control"
                              id="total_opening"
                              name="total_opening"
                              defaultValue={formData.total_opening}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="form-group mb-3 col-md-3">
                            <label htmlFor="is_urgent">Is Job Urgent?</label>
                            <select
                              className="form-control"
                              id="is_urgent"
                              name="is_urgent"
                              value={formData.is_urgent}
                              onChange={handleChange}
                            >
                              <option value={1}>Yes</option>
                              <option value={0}>No</option>
                            </select>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="text-lg-end">
                              <button type="submit" className="btn btn-primary">
                                Save and Continue <i className="fas fa-angle-right" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>
      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default JobPostStep1;
