import React, { useState, useEffect } from "react";
import * as api from "../../../../../services/ApiService";
import * as tokenUtils from "../../../../../utils/tokenUtils";

function Document ({ profileInfo }) {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [workHistories, setWorkHisstories] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchWorkHistoryData = async () => {
    try {
      const param = {
        user_id: profileInfo.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "user/get-documents");
      //const apiDataObj = Object.setPrototypeOf(apiData, Object.prototype);

      /*
      const apiDataObj = apiData.reduce((acc, currentValue, index) => {
        acc[index] = currentValue;
        return acc;
      }, {});
      */

      setWorkHisstories(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkHistoryData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${formattedTime}`;
  }; 

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return `${formattedDate}`;
  };
  return (
    <>
      <div className="col-md-12">
        <div className="table-responsive">
          <table className="table table-centered table-nowrap mb-0">
            <thead className="table-light">
            <tr>
                              <th>Title</th>
                              <th>Type</th>
                              <th>Uploaded On</th>
                              <th>Action</th>
                            </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="3">Loading...</td>
                </tr>
              ) : (
                <>
                  {noRecordsFound ? (
                    <tr>
                      <td colSpan="3">No records found</td>
                    </tr>
                  ) : (
                    <>
                    {Object.entries(workHistories).map(([key, value]) => (
                                      <tr key={value.id}>
                                        <td>{value.title}</td>
                                        <td>{value.doc_type_name}</td>
                                        <td>
                                          {formatDate(value.created_at)}{" "}
                                          <small className="text-muted">
                                            {formatTime(value.created_at)}
                                          </small>
                                        </td>
                                        <td>
                                          <a
                                            href={value.dir_path}
                                            download
                                            target="_blank"
                                            rel="noreferrer"
                                            className="btn btn-secondary btn-xs me-1"
                                            data-tooltip-id="mt-4"
                                            data-tooltip-content="Download Document"
                                          >
                                            <i className="mdi mdi-download" />
                                          </a>
                                          {/* <Tooltip id="mt-4"></Tooltip> */}

                                          
                                          {/* <Tooltip id="mt-2"></Tooltip> */}
                                        </td>
                                      </tr>
                                    ))}
                    </>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Document;
