import React, { useState, useEffect } from "react";
import Layout from "../../../Layout/Index";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Modal from "react-bootstrap/Modal";
import * as api from "../../../../services/ApiService";
import * as Yup from "yup";
import * as tokenUtils from "../../../../utils/tokenUtils";
import { toast } from "react-toastify";
import Spinner from "../../../Loader/Spinner";
import PageTitle from "../../../Layout/PageTitle";
import { formatDate, phoneRegExp } from "../../../../utils/dateFormat";
import useConfirm from "../../../../hooks/useConfirm";
import { formatPhoneNumber } from "../../../../utils/phoneNumberFormatter";
const AdminAgenciesList = () => {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
    state_id: "",
    user_status: "all",
  });

  const [isEditing, setIsEditing] = useState(false);

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
      state_id: "",
      user_status: "all",
    };
    setFilters(defaultFilters);
    fetchApplicants();
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      fetchApplicants();
    }
  }, [showFilterDropdown]);

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  const [formData, setformData] = useState({
    id: null,
    user_id: user.id,
    name: null,
    email: null,
    phone: null,
    bio: null,
    state_id: null,
    city_id: null,
    role_id: 3,
    password: "",
    company_name: "",
    website: "",
    primary_industry: "",
    company_size: "",
    company_bio: "",
    address_line1: "",
    address_line2: "",
    founded_in: "",
    linkedin_url: "",
    facebook_url: "",
    twitter_url: "",
    instagram_url: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const [states, setStates] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchStates = async () => {
      try {
        const apiData = await api.getMethod("get-states");
        setStates(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchStates();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [cities, setCities] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-cities/" + formData.state_id);

        setCities(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, [formData.state_id]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [applicants, setApplicants] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchApplicants = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
        role_id: 3,
      };
      const queryParams = { ...param, ...filters };
      const apiData = await api.postMethod(queryParams, "admin/get-users");
      //const apiDataObj = Object.setPrototypeOf(apiData, Object.prototype);

      setApplicants(apiData.data);
      setLoading(false);
      setNoRecordsFound(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchApplicants();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [uploadAddOpen, setAddModalOpen] = useState(false);

  const toggleAddModal = () => {
    setAddModalOpen(!uploadAddOpen);
    setIsEditing(false);

    if (!uploadAddOpen) {
      setformData({
        id: null,
        user_id: user.id,
        name: null,
        email: null,
        phone: null,
        bio: null,
        state_id: null,
        city_id: null,
        role_id: 3,
        password: null,
      });

      // Reset validation errors
      setErrors({});
    }
  };

  const handleEdit = (record) => {
    // Populate formData with the values of the selected record
    setformData({
      user_id: user.id,
      id: record.id,
      name: record.name,
      email: record.email,
      phone: record.phone,
      bio: record.bio,
      state_id: record.state_id,
      city_id: record.city_id,
      role_id: 3,

      company_name: record.company_name,
      website: record.website,
      primary_industry: record.primary_industry,
      company_size: record.company_size,
      company_bio: record.company_bio,
      address_line1: record.address_line1,
      address_line2: record.address_line2,
      founded_in: record.founded_in,
      linkedin_url: record.linkedin_url,
      facebook_url: record.facebook_url,
      twitter_url: record.twitter_url,
      instagram_url: record.instagram_url,
    });

    setIsEditing(true);

    setAddModalOpen(true);
  };
  const { confirm } = useConfirm();
  const handleDelete = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      setIsLoading(true);
      record.user_id = user.id;
      const result = await api.postMethod(record, "admin/delete-user");
      setIsLoading(false);
      if (result.status) fetchApplicants();
    }
  };

  //Define the validation schema

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Full Name is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    password: isEditing
      ? Yup.string().nullable()
      : Yup.string()
          .min(6, "Password must be at least 6 characters")
          .required("Password is required"),
    phone: Yup.string().required("Phone Number is required"),
    state_id: Yup.string().required("State is required"),
    city_id: Yup.string().required("City is required"),
    /*bio: Yup.string(),*/
    company_name: Yup.string().required("Company Name is required"),
    website: Yup.string().required("Website is required"),
    primary_industry: Yup.string().required("Primary Industry is required"),
    company_size: Yup.string().notRequired(),
    /*company_bio: Yup.string(),*/
    address_line1: Yup.string().required("Address Line 1 is required"),
    address_line2: Yup.string().notRequired(),
    founded_in: Yup.number()
      .typeError("Year must be a number")
      .integer("Invalid year format")
      .min(1900, "Year must be greater than or equal to 1900")
      .max(new Date().getFullYear(), `Year cannot be greater than ${new Date().getFullYear()}`)
      .notRequired(),
    /*linkedin_url: Yup.string().url("Invalid URL format"),
    facebook_url: Yup.string().url("Invalid URL format"),
    instagram_url: Yup.string().url("Invalid URL format"),
    twitter_url: Yup.string().url("Invalid URL format"), */
  });

  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsEditing(false);

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      setIsLoading(true);
      const result = await api.postFileMethod(formData, "admin/update-agency");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);

        //document.getElementById("file_name").value = "";
        setAddModalOpen(false);
        fetchApplicants();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  /* Select All checkbox functions start */

  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [bulkAction, setBulkAction] = useState("");

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      const allUserIds = applicants.map((record) => record.id);
      setSelectedUserIds(allUserIds);
    } else {
      setSelectedUserIds([]);
    }
  };

  const handleCheckboxChange = (event) => {
    const userId = parseInt(event.target.value);
    setSelectedUserIds((prevSelectedUserIds) => {
      if (event.target.checked) {
        // Add the user ID to the list
        return [...prevSelectedUserIds, userId];
      } else {
        // Remove the user ID from the list
        return prevSelectedUserIds.filter((id) => id !== userId);
      }
    });
  };

  const handleBulkActionChange = (event) => {
    setBulkAction(event.target.value);
  };

  const handleBulkActionSubmit = async () => {
    if (bulkAction === "") {
      toast.error("Please any action from bulk actions");
    } else if (selectedUserIds.length === 0) {
      toast.error("Please select atleast 1 record to perform bulk action");
    } else {
      try {
        setIsLoading(true);
        const param = {
          user_id: user.id,
          user_ids: selectedUserIds,
          bulk_action: bulkAction,
          role_id: 3,
        };
        const result = await api.postFileMethod(param, "admin/user-bulk-actions");
        setIsLoading(false);
        if (result.status) {
          toast.success(result.message);
          setSelectedUserIds([]);
          setBulkAction("");
          fetchApplicants();
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }

    /*
    if (bulkAction === "delete") {
      
      axios
        .post("/api/delete-users", { user_ids: selectedUserIds })
        .then((response) => {
          setRecords(records.filter((record) => !selectedUserIds.includes(record.id)));
          setSelectedUserIds([]);
        })
        .catch((error) => console.error("There was an error deleting the users!", error));
      
    } else if (bulkAction === "change-status") {
      
      axios.post('/api/change-status', { user_ids: selectedUserIds, status: 'inactive' })
        .then(response => {
          setRecords(records.map(record => 
            selectedUserIds.includes(record.id) ? { ...record, status: 'inactive' } : record
          ));
          setSelectedUserIds([]);
        })
        .catch(error => console.error('There was an error changing the status!', error));
        
    }
    */
  };

  const updateStatus = async (id, status) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        id: id,
        status: status,
        role_id: 3,
      };
      const result = await api.postFileMethod(param, "admin/update-user-status");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchApplicants();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const updateUserFieldValue = async (id, field, value) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        id: id,
        field: field,
        value: value,
        role_id: 3,
      };
      const result = await api.postMethod(param, "admin/update-user-field");
      // Update the state with the new data
      setApplicants(
        applicants.map((record) => (record.id === id ? { ...record, [field]: value } : record))
      );
      fetchApplicants();
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const breadcrumbItems = [
    { text: "Admin Dashboard", link: "/admin/dashboard" },
    { text: "Agencies", link: null },
  ];

  const formatInput = (e) => {
    let inputValue = e.target.value.replace(/\D/g, ''); // Remove non-digit characters

    // Format input as XXX-XXX-XX
    if (inputValue.length <= 3) {
      inputValue = inputValue;
    } else if (inputValue.length <= 6) {
      inputValue = `${inputValue.slice(0, 3)}-${inputValue.slice(3)}`;
    } else {
      inputValue = `${inputValue.slice(0, 3)}-${inputValue.slice(3, 6)}-${inputValue.slice(6, 10)}`;
    }

    // Set formatted value back to state
    setformData({
      ...formData,
      ["phone"]: inputValue,
    });
    console.log(inputValue)
  };

  return (
    <Layout>
      <PageTitle title="Agencies" breadcrumbItems={breadcrumbItems} />

      {/* end page title */}
      <div className="row">
        <div className="col-12">
          <div className="card custom-box-shadow">
            <div className="card-body">
              <div className="row justify-content-between mb-2">
                <div className="col-md-6">
                  <div className="">
                    <select
                      className="form-select my-1 my-lg-0 w-auto d-inline me-1"
                      value={bulkAction}
                      onChange={handleBulkActionChange}
                    >
                      <option value="">Bulk Actions</option>
                      <option value="delete">Delete</option>
                      <option value="change-status-active">Change Status to Active</option>
                      <option value="change-status-inactive">Change Status to In-Active</option>
                      <option value="change-status-block">Change Status to Block</option>
                      <option value="change-status-terminate">Change Status to Terminate</option>
                    </select>
                    <button onClick={handleBulkActionSubmit} className="btn btn-info">
                      Apply Action
                    </button>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="dropdown me-2" style={{ width: 300 }}>
                    <button
                      type="button"
                      className="btn btn-outline-light dropdown-toggle w-100 text-start"
                      onClick={handleToggleFilterDropDown}
                    >
                      Filters <i className="mdi mdi-chevron-down float-end" />
                    </button>
                    <div className={`dropdown-menu  ${showFilterDropdown ? "show" : ""}`}>
                      <form className="px-2 py-2" onSubmit={handleFilterSubmit}>
                        <div className="mb-2">
                          <label htmlFor="keyword" className="form-label">
                            Keyword
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="keyword"
                            name="keyword"
                            value={filters.keyword}
                            onChange={handleFilterInputChange}
                          />
                        </div>
                        <div className="mb-2">
                          <label htmlFor="state_id" className="form-label">
                            Desired State
                          </label>
                          <select
                            className="form-control"
                            id="state_id"
                            name="state_id"
                            onChange={handleFilterInputChange}
                            value={filters.state_id}
                          >
                            <option value="" key="">
                              Please Select
                            </option>
                            {/* Render the states */}
                            {states.map((state) => (
                              <option key={state.id} value={state.id}>
                                {state.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="mb-2">
                          <label htmlFor="user_status" className="form-label">
                            Status
                          </label>
                          <select
                            name="user_status"
                            id="user_status"
                            className="form-control"
                            onChange={handleFilterInputChange}
                            value={filters.user_status}
                          >
                            <option value="all">Please Select</option>
                            <option value={1}>Active</option>
                            <option value={0}>In-Active</option>
                          </select>
                        </div>
                        <div className="text-end">
                          <button
                            type="reset"
                            className="btn btn-light float-start"
                            onClick={handleReset}
                          >
                            Reset Filter
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Apply Filter
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="text-lg-end my-1 my-lg-0">
                    {/* 
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light mb-2 me-1"
                    >
                      <i className="fas fa-upload mr-1" /> Import Agencies
                    </button>
                    */}
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light mb-2 me-1"
                      onClick={toggleAddModal}
                    >
                      <i className="fa fa-plus mr-1" /> Add New Agency
                    </button>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="form-checks">
                        <input
                          type="checkbox"
                          onChange={handleSelectAllChange}
                          checked={
                            selectedUserIds.length === applicants.length && applicants.length > 0
                          }
                          className="form-check-input"
                        />
                      </th>
                      <th>Company Title</th>
                      <th>Admin Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Status</th>
                      <th>Registered On</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="9">Loading...</td>
                      </tr>
                    ) : (
                      <>
                        {noRecordsFound ? (
                          <tr>
                            <td colSpan="9">No records found</td>
                          </tr>
                        ) : (
                          <>
                            {Object.entries(applicants).map(([key, value]) => (
                              <tr key={value.id}>
                                <td className="form-checks">
                                  <input
                                    type="checkbox"
                                    value={value.id}
                                    name="user_ids"
                                    onChange={handleCheckboxChange}
                                    checked={selectedUserIds.includes(value.id)}
                                    className="form-check-input"
                                  />
                                </td>
                                <td>
                                  <Link
                                    to={`/admin/agency-profile/` + value.unique_id}
                                    className=""
                                  >
                                    {value.profile_pic_path && (
                                      <img
                                        src={value.profile_pic_path}
                                        className="rounded-circle"
                                        alt="user-img"
                                        height={32}
                                      />
                                    )}{" "}
                                    {value.company_name}
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={`/admin/agency-profile/` + value.unique_id}
                                    className=""
                                  >
                                    {value.name}
                                  </Link>
                                </td>
                                <td>{value.email || "-"}</td>
                                <td>{value.phone ? formatPhoneNumber(value.phone, "1") : "-"}</td>
                                <td>
                                  {value.status == 1 && (
                                    <label className="badge badge-soft-success">Active</label>
                                  )}
                                  {value.status == 0 && (
                                    <label className="badge badge-soft-warning">In-Active</label>
                                  )}
                                  {value.status == 2 && (
                                    <label className="badge badge-soft-danger">Blocked</label>
                                  )}
                                  {value.status == 3 && (
                                    <label className="badge badge-soft-info">Terminated</label>
                                  )}
                                </td>
                                <td>
                                  {formatDate(value.created_at, "MMM DD, YYYY")}{" "}
                                  <small className="text-muted">
                                    {formatDate(value.created_at, "hh:mm A")}
                                  </small>
                                </td>
                                <td>
                                  {value.user_status == 0 && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-default action-icon"
                                        data-tooltip-id="mt-1"
                                        data-tooltip-content="In-Active"
                                        onClick={() => updateStatus(value.id, 1)}
                                      >
                                        <i className="mdi mdi-close-box-outline" />
                                      </button>
                                      <Tooltip id="mt-1"></Tooltip>
                                    </>
                                  )}
                                  {value.user_status == 1 && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-default action-icon"
                                        data-tooltip-id="mt-1"
                                        data-tooltip-content="Active"
                                        onClick={() => updateStatus(value.id, 0)}
                                      >
                                        <i className="mdi mdi-check-box-outline" />
                                      </button>
                                      <Tooltip id="mt-1"></Tooltip>
                                    </>
                                  )}

                                  <button
                                    type="button"
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-2"
                                    data-tooltip-content="Edit Agency"
                                    onClick={() => handleEdit(value)}
                                  >
                                    <i className="mdi mdi-pencil" />
                                  </button>
                                  <Tooltip id="mt-2"></Tooltip>

                                  <Link
                                    to={`/admin/agency-profile/` + value.unique_id}
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-3"
                                    data-tooltip-content="View Agency Profile"
                                  >
                                    <i className="mdi mdi-eye" />
                                  </Link>
                                  <Tooltip id="mt-3"></Tooltip>

                                  <button
                                    type="button"
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-2"
                                    data-tooltip-content="Remove Agency"
                                    onClick={() => handleDelete(value)}
                                  >
                                    <i className="mdi mdi-delete" />
                                  </button>
                                  <Tooltip id="mt-2"></Tooltip>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* end card-body*/}
          </div>
          {/* end card*/}
        </div>
        {/* end col */}
      </div>

      <Modal show={uploadAddOpen} onHide={toggleAddModal} centered size="lg">
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0">Add Agency</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {/* User Details Section */}
              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="name">
                Admin Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  onChange={handleChange}
                  defaultValue={formData.name !== null ? formData.name : ""}
                />
                {errors.name && <span className="error">{errors.name}</span>}
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="email">
                  Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  defaultValue={formData.email !== null ? formData.email : ""}
                />
                {errors.email && <span className="error">{errors.email}</span>}
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="password">
                  Password
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  name="password"
                  onChange={handleChange}
                />
                {errors.password && <span className="error">{errors.password}</span>}
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="phone">
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  name="phone"
                  onChange={(e)=>formatInput(e)}
                  value={formData.phone !== null ? formData.phone : ""}
                />
                {errors.phone && <span className="error">{errors.phone}</span>}
              </div>

              <div className="col-md-4 mb-2">
                <label htmlFor="state_id" className="form-label">
                  State
                </label>
                <select
                  className="form-control"
                  name="state_id"
                  onChange={handleChange}
                  value={formData.state_id !== null ? formData.state_id : ""}
                >
                  <option value="" key="">
                    Please Select
                  </option>
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
                {errors.state_id && <span className="error">{errors.state_id}</span>}
              </div>

              <div className="col-md-4 mb-2">
                <label htmlFor="city_id" className="form-label">
                  City
                </label>
                <select
                  className="form-control"
                  name="city_id"
                  onChange={handleChange}
                  value={formData.city_id !== null ? formData.city_id : ""}
                >
                  <option value="" key="">
                    Please Select
                  </option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.city_name}
                    </option>
                  ))}
                </select>
                {errors.city_id && <span className="error">{errors.city_id}</span>}
              </div>

              <div className="col-md-12 mb-2">
                <label className="form-label" htmlFor="bio">
                  Bio
                </label>
                <textarea
                  rows={5}
                  className="form-control"
                  id="bio"
                  name="bio"
                  onChange={handleChange}
                  defaultValue={formData.bio !== null ? formData.bio : ""}
                ></textarea>
              </div>
              <h4 className="mt-2">Company Details</h4>
              <hr />

              {/* Company Details Section */}
              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="company_name">
                  Company Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="company_name"
                  name="company_name"
                  onChange={handleChange}
                  defaultValue={formData.company_name !== null ? formData.company_name : ""}
                />
                {errors.company_name && <span className="error">{errors.company_name}</span>}
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="website">
                  Website
                </label>
                <input
                  type="text"
                  className="form-control"
                  pattern=".*(\.com|\.in|\.org|\.us|\.eu|\.net |\.io|\.ai|\.co.uk|\.ca|\.dev |\.me|\.co|\.de|\.app|\.is|\.gg|\.to|\.ph|\.nl|\.xyz|\.club|\.online|\.info|\.store|\.best|\.live|\.tech|\.pw|\.pro|\.uk|\.tv|\.cx|\.mx|\.fm|\.cc|\.world|\.space|\.vip|\.life|\.shop|\.host|\.fun|\.biz|\.icu|\.design|\.art)$"
                  id="website"
                  name="website"
                  onChange={handleChange}
                  defaultValue={formData.website !== null ? formData.website : ""}
                />
                {errors.website && <span className="error">{errors.website}</span>}
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="primary_industry">
                  Primary Industry
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="primary_industry"
                  name="primary_industry"
                  onChange={handleChange}
                  defaultValue={formData.primary_industry !== null ? formData.primary_industry : ""}
                />
                {errors.primary_industry && (
                  <span className="error">{errors.primary_industry}</span>
                )}
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="company_size">
                  Company Size
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="company_size"
                  name="company_size"
                  onChange={handleChange}
                  defaultValue={formData.company_size !== null ? formData.company_size : ""}
                />
                {errors.company_size && <span className="error">{errors.company_size}</span>}
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="founded_in">
                  Founded In
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="founded_in"
                  name="founded_in"
                  onChange={handleChange}
                  defaultValue={formData.founded_in !== null ? formData.founded_in : ""}
                />
                {errors.founded_in && <span className="error">{errors.founded_in}</span>}
              </div>

              <div className="col-md-12 mb-2">
                <label className="form-label" htmlFor="company_bio">
                  Company Bio
                </label>
                <textarea
                  rows={5}
                  className="form-control"
                  id="company_bio"
                  name="company_bio"
                  onChange={handleChange}
                  defaultValue={formData.company_bio !== null ? formData.company_bio : ""}
                ></textarea>
              </div>

              <div className="col-md-6 mb-2">
                <label className="form-label" htmlFor="address_line1">
                  Address Line 1
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address_line1"
                  name="address_line1"
                  onChange={handleChange}
                  defaultValue={formData.address_line1 !== null ? formData.address_line1 : ""}
                />
                {errors.address_line1 && <span className="error">{errors.address_line1}</span>}
              </div>

              <div className="col-md-6 mb-2">
                <label className="form-label" htmlFor="address_line2">
                  Address Line 2
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address_line2"
                  name="address_line2"
                  onChange={handleChange}
                  defaultValue={formData.address_line2 !== null ? formData.address_line2 : ""}
                />
                {errors.address_line2 && <span className="error">{errors.address_line2}</span>}
              </div>

              <div className="col-md-12">
                <h5>Social Links</h5>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="facebook_url" className="form-label">
                    Facebook
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text p-2">
                        <i className="fe-facebook"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="facebook_url"
                      name="facebook_url"
                      onChange={handleChange}
                      defaultValue={formData.facebook_url==null?"":formData.facebook_url}
                      placeholder="Enter your Facebook URL"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="twitter_url" className="form-label">
                    Twitter
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text p-2">
                        <i className="fe-twitter"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="twitter_url"
                      name="twitter_url"
                      onChange={handleChange}
                      defaultValue={formData.twitter_url=="null"?"":formData.twitter_url}
                      placeholder="Enter your Twitter URL"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="instagram_url" className="form-label">
                    Instagram
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text p-2">
                        <i className="fe-instagram"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="instagram_url"
                      name="instagram_url"
                      onChange={handleChange}
                      defaultValue={formData.instagram_url=="null"?"":formData.instagram_url}
                      placeholder="Enter your Instagram URL"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="linkedin_url" className="form-label">
                    Linkedin
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text p-2">
                        <i className="fe-linkedin"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="linkedin_url"
                      name="linkedin_url"
                      onChange={handleChange}
                      defaultValue={formData.linkedin_url=="null"?"":formData.linkedin_url}
                      placeholder="Enter your Linkedin URL"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={toggleAddModal}>
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {isLoading === true && <Spinner />}
    </Layout>
  );
};

export default AdminAgenciesList;
