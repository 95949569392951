import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import { Tooltip } from "react-tooltip";
import Modal from "react-bootstrap/Modal";
import * as tokenUtils from "../../../utils/tokenUtils";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import PageTitle from "../../Layout/PageTitle";
import { currentRole } from "../../Layout/HelmetComponent";
import { formatDate } from "../../../utils/dateFormat";
import * as api from "../../../services/ApiService";
import useConfirm from "../../../hooks/useConfirm";

import * as Yup from "yup";
import moment from "moment/moment";
import { Button } from "react-bootstrap";
import { formatAmount } from "../../../utils/AmountFormatter";
function AdminJobRequest() {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  const location = useLocation();
  const isBoostedJobs = location.pathname.includes("boosted-jobs");
  const isStaff = location.pathname.includes("/staff");
  const urlSlug = isStaff ? "staff" : "client";
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);


  const [showJobFilterDropdown, setJobFilterDropdown] = useState(false);
  const handleToggleJobFilterDropDown = () => {
    setJobFilterDropdown(!showJobFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    job_title: "",
    state_id: "",
    date_posted: "",
    is_hide: 0,
    order_by: "default",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      Name: "",
      state_id: "",

    };
    setFilters(defaultFilters);
    
    setJobFilterDropdown(false);
  };

  useEffect(() => {
    if (showJobFilterDropdown === false) {
    }
  }, [showJobFilterDropdown]);

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };


; // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [boostModal, setBoostModal] = useState(false);


  // State for filter values
  const [formData, setformData] = useState({
    user_id: user.id,
    job_id: "",
    amount: "",
    startDate: "",
    endDate: "",
  });
  const [jobRequset,setJobRequest] = useState()
  const [states,setStates]= useState()
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-states");
        setStates(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []);

const getJobRequest = async()=>{
  try{
    const payload ={
      client_id:user.id
    }
    const apiData = await api.postMethod(payload,"admin/job-requests/get-all-job-request");
    if(apiData){
      setJobRequest(apiData.data)
    }
  }
  catch(error){
    console.log(error)
  }
}
useEffect(()=>{
  getJobRequest()
},[])
  // const { confirm } = useConfirm();


  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: `/admin/dashboard` },
    { text: isBoostedJobs ? "Boosted Jobs" : "Job Request", link: null },
  ];

  const sendMatchedOppertunity = async(value)=>{
    try{
      const payload ={
        client_id:user.id,
        user_id:value.user_id,
        job_request_id:value.id,
        job_id:value.job_id
      }
      const apiData = await api.postMethod(payload,"client/job-requests/send-job-opportunity");
      if(apiData){
        // console.log(apiData.data)
      toast.success("Job Request Send Successfully")

      }
    }
    catch(error){
      console.log(error)
    }
  }

  const handleSubmit=async(e)=>{
    e.preventDefault()
    try{
      const payload={
        client_id:user.id,
        // state_id:filters.state_id
      }
const apiData = await api.postMethod(payload,`admin/job-requests/get-all-job-request?state_id=${filters.state_id}&name=${filters.job_title}&is_hide=${filters.is_hide}`)
// const apiData2 = await api.postMethod(payload,`client/job-requests/get-matched-job-request?state_id=${filters.state_id}&name=${filters.job_title}&is_hide=${filters.is_hide}`)

// console.log("data",apiData.data)
    if(apiData){
      setJobRequest(apiData.data)
      // setMatchedJobRequest(apiData2.data)
      handleToggleJobFilterDropDown()
    }
    }
    catch(error){
      console.log(error)
    }
  }
  // const hideJobRequest = async(value)=>{
  //   try{
  //     const payload={
  //       client_id:user.id,
  //       job_request_id:value.id,
  //       is_hide:1
  //     }
  //     const apiData = api.postMethod(payload,"client/job-requests/hide-job-request")
  //     if(apiData){
  //       toast.success("Job opportunity Successfully Hidden ");
  //       getJobRequest()
  //     }
  //   }
  //   catch(error){
  //     console.log(error)
  //   }
  // }

  const [openStateModal, setOpenStateModal] = useState(false);

  const toggleStateModal = () => {
    setOpenStateModal(!openStateModal);

    if (!openStateModal) {
      // Reset validation errors
      // setErrors({});
    }
  };
  const [openStateModal2, setOpenStateModal2] = useState(false);
  const toggleStateModal2 = () => {
    setOpenStateModal2(!openStateModal2);

    if (!openStateModal2) {
      // Reset validation errors
      // setErrors({});
    }
  };

const [cityState, setCityState] = useState();
const [showoppertunityValue,setShowOppertunityValue] = useState()
// const DisplayValues = (values, maxDisplay = 3) => {
//   // console.log(values);
//   if (!values || values.length === 0) return <span>No Location Found</span>;
//   const displayText =
//     values.length <= maxDisplay
//       ? values.join(", ")
//       : values.slice(0, maxDisplay).join(", ") + ", ...";
//   return displayText;
// };
  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              <PageTitle    
                title={isBoostedJobs ? "Boosted Jobs" : "Job Requests"}
                breadcrumbItems={breadcrumbItems}
              />

              <div className="row">
                <div className="col-12">
                  <div className="p-4 card custom-box-shadow">
                    <div className="card-body">
                      <div className="row justify-content-between">
                        <div className="col-auto">
                          <div className="dropdown" style={{ width: 300 }}>
                            <button
                              type="button"
                              className="btn btn-outline-light dropdown-toggle w-100 text-start"
                              onClick={handleToggleJobFilterDropDown}
                            >
                              Filter and Search Jobs
                              <i className="mdi mdi-chevron-down float-end" />
                            </button>
                            <div className={`dropdown-menu ${showJobFilterDropdown ? "show" : ""}`}>
                              <form className="px-2 py-2" 
                              onSubmit={handleSubmit}
                              >
                                <div className="mb-2">
                                  <label htmlFor="job_title" className="form-label">
                                   User Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="job_title"
                                    name="job_title"
                                    value={filters.job_title}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                <div className="mb-2">
                                  <label htmlFor="state_id" className="form-label">
                                    Location
                                  </label>
                                  <select
                                    className="form-control mb-2"
                                    id="state_id"
                                    name="state_id"
                                    value={filters.state_id}
                                    onChange={handleInputChange}
                                  >
                                    <option value="" key="">
                                      Please Select
                                    </option>
                                    {/* Render the states */}
                                    {states&&states.map((state) => (
                                      <option key={state.id} value={state.id}>
                                        {state.name}
                                      </option>
                                    ))}
                                  </select>

                                 
                                </div>
                                
                                <div className="text-end">
                                  <Button
                                    type="button"
                                    className="btn btn-light float-start"
                                    onClick={()=>{getJobRequest();handleToggleJobFilterDropDown()}}
                                  >
                                    Reset Filter
                                  </Button>
                                  <button type="submit" className="btn btn-primary">
                                    Apply Filter
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>

                        {/* end col*/}
                      </div>
                    </div>

                    <div style={{overflowX:"initial"}} className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                     
                      <th>Applicant</th>
                      <th>Posted </th>
                      <th>Oppertunity Sent</th>
                      <th>Sent Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      <>
                        {
                          <>
                            {jobRequset&&Object.entries(jobRequset).map(([key, value]) => (
                              <tr style={{marginBottom:"1vw"}} key={value.job_id} >
                                {/* <td className="form-checks">
                                  <input
                                    type="checkbox"
                                    // value={value.job_application_id}
                                    name="job_ids"
                                    // onChange={handleCheckboxChange}
                                    // checked={selectedJobIds.includes(value.job_application_id)}
                                    className="form-check-input"
                                  />
                                </td> */}
                                <td>
                                <Link to={`${value?.id}`} className="">
                                        {value?.user_name}{" "}
                                        Title
                                      </Link>
                                </td>
                                <td>
                                {moment(value.created_at).format("MMM DD, YYYY")}
                                </td>
                                <td>
                                  {
                                    value?.job_opportunities[0]?.company_name
                                  }
                                  {value?.job_opportunities.length > 1 ? (
            <span
              onClick={() => {
                setShowOppertunityValue(value?.job_opportunities);
                toggleStateModal2();
              }}
              style={{
                marginLeft: "1vw",
                background: "#43bfe5",
                padding: 3,
                fontSize: "12px",
                borderRadius: 50,
                color: "white",
                cursor: "pointer",
              }}
            >
              +{value?.job_opportunities.length - 1}
            </span>
          ) : (
            ""
          )}
                                </td>
                                
                                <td>
                                {
                                    moment(value?.job_opportunities[0]?.sent_date).format("MMM/DD/YYYY")
                                  }
                                </td>
                                <td>
                                {value.job_opportunities[0]?.user_response == "Not Interested"?<span style={{marginLeft:"1vw"}} className="badge bg-danger ml-1">{value.job_opportunities[0]?.user_response} </span>:<span style={{marginLeft:"1vw"}} className="badge bg-success">{value.job_opportunities[0]?.user_response}</span>}
                                </td>
                                <td>
                                <Dropdown className="">
                                      <Dropdown.Toggle
                                        variant="default"
                                        // id={`dropdown-basic-` + value.job_id}
                                        // key={`dropdown-basic-` + value.job_id}
                                        className="p-0"
                                      >
                                        <i className="mdi mdi-dots-horizontal m-0 text-muted h3" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                          <Dropdown.Item onClick={()=>navigate(`/admin/job-request/${value.id}`)} >
                                          Review Details
                                          </Dropdown.Item>
                                          {/* <Dropdown.Item onClick={()=>hideJobRequest(value)}>
                                          Hide
                                        </Dropdown.Item> */}
                                          {/* <Dropdown.Item onClick={()=>toggleStateModal()} >
                                           Message
                                          </Dropdown.Item> */}
                                        <Dropdown.Item onClick={()=>navigate("/admin/sent_job_request",{state:{userId:value.user_id,jobRequest:value?.id,UserData:value,userId:value.user_id}})}>
                                        Send Opportunity
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                              </tr>
                            ))}
                          </>
                        }
                      </>
                    }
                  </tbody>
                </table>
              </div>

                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>
                {/* end col */}
              </div>



              {/* end row */}
            </div>

            {/* container */}
            <div >

        </div>
          </div>

          <Footer />
        </div>
      </div>

      <ThemeSettings />
      {isLoading === true && <Spinner />}
      <Modal
        show={openStateModal}
        onHide={toggleStateModal}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0">State / Cities</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 ">
              <p>
                <strong style={{ marginBottom: "2vw" }}>Locations</strong>
              </p>
              {cityState &&
                cityState?.state_city_data.map((data) => (
                  <>
                    <p>
                      <strong>{data.state}</strong> |{" "}
                      {data && data.cities.map((city) => <span> {city},</span>)}
                    </p>
                  </>
                ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-secondary" onClick={toggleStateModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={openStateModal2}
        onHide={toggleStateModal2}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Oppertunity Sent By</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 ">
            <div style={{overflowX:"initial"}} className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>Oppertunity Sent</th>
                      <th>Sent Date</th>
                      <th>Status</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {
                      <>
                        {
                          <>
                            {showoppertunityValue&&Object.entries(showoppertunityValue).map(([key, value]) => (
                              <tr style={{marginBottom:"1vw"}} key={value.job_id} >
                                <td>
                                 {value.company_name}
                                </td>
                                
                                <td>
                                {moment(value.sent_date).format("MMM/DD/YYYY")}
                                </td>
                                <td>
                                {value?.user_response == "Not Interested"?<span style={{marginLeft:"1vw"}} className="badge bg-danger ml-1">{value?.user_response} </span>:<span style={{marginLeft:"1vw"}} className="badge bg-success">{value?.user_response}</span>}
                                 </td>
                               
                              </tr>
                            ))}
                          </>
                        }
                      </>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-secondary" onClick={toggleStateModal2}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminJobRequest;
