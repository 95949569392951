import React, { useState, useEffect } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Modal from "react-bootstrap/Modal";
import * as api from "../../../services/ApiService";
import * as Yup from "yup";
//import * as document from "../../../services/DocumentService";
import * as tokenUtils from "../../../utils/tokenUtils";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import useConfirm from "../../../hooks/useConfirm";
import Announcement from "../../UI/Announcement";

function Documents() {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [isLoading, setIsLoading] = useState(false);
  const [docTypes, setDocTypes] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchDocTypes = async () => {
      try {
        const result = await api.getMethod("get-doc-types");
        if (result.status) setDocTypes(result.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchDocTypes();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [formData, setformData] = useState({
    id: null,
    user_id: user.id,
    title: null,
    doc_type_id: null,
    file_name: null,
    file_type: null,
    expiry_date: null,
  });

  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const toggleUploadModal = () => {
    setUploadModalOpen(!uploadModalOpen);

    if (!uploadModalOpen) {
      setformData({
        id: null,
        user_id: user.id,
        title: null,
        doc_type_id: null,
        file_name: null,
        file_type: null,
        expiry_date: null,
      });

      // Reset validation errors
      setErrors({});
    }
  };

  const handleEdit = (record) => {
    // Populate formData with the values of the selected record
    setformData({
      user_id: user.id,
      id: record.id,
      title: record.title,
      doc_type_id: record.doc_type_id,
      file_name: record.file_name,
      file_type: record.file_type,
      expiry_date: record.expiry_date,
    });

    setUploadModalOpen(true);
  };

  const { confirm } = useConfirm();
  const handleDelete = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      setIsLoading(true);
      record.user_id = user.id;
      const result = await api.postMethod(record, "user/delete-document");
      setIsLoading(false);
      if (result.status) fetchDocuments();
    }
  };

  //Define the validation schema
  const validationSchema = Yup.object({
    title: Yup.string().required("Document Title is Required"),
    doc_type_id: Yup.string().required("Document Type is Required"),
    file_name: Yup.string().required("Document is Required"),
  });

  const [errors, setErrors] = useState({});

  const [documents, setDocuments] = useState({});
  const [loading, setLoading] = useState(false);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchDocuments = async () => {
    try {
      const param = {
        user_id: user.id,
      };
      const result = await api.postMethod(param, "user/get-documents");
      //const apiDataObj = Object.setPrototypeOf(apiData, Object.prototype);

      if (result.status) {
        let apiData = result.data;
        const apiDataObj = apiData.reduce((acc, currentValue, index) => {
          acc[index] = currentValue;
          return acc;
        }, {});

        setDocuments(apiDataObj);
        // Check if data array is empty
        if (apiData.length === 0) {
          setNoRecordsFound(true);
        }
        setIsLoading(false);
      } else setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchDocuments();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      setIsLoading(true);
      const result = await api.postFileMethod(formData, "user/update-document");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        window.location.reload()
        //document.getElementById("file_name").value = "";
        setUploadModalOpen(false);
        fetchDocuments();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const handleFileChange = (e) => {
    setformData({
      ...formData,
      file_name: e.target.files[0], // Update image field with the selected file
    });
  };

  const [shareModalOpen, setShareModalOpen] = useState(false);

  const [documentShareData, setDocumentShareData] = useState({});

  const toggleShareModal = () => {
    setShareModalOpen(!shareModalOpen);

    if (!shareModalOpen) {
      setDocumentShareData({
        title: null,
        doc_id: null,
        user_id: user.id,
        share_to: null,
      });

      // Reset validation errors
      setShareErrors({});
    }
  };

  const handleShareHistory = (record) => {
    // Populate formData with the values of the selected record
    setDocumentShareData({
      user_id: user.id,
      doc_id: record.id,
      title: record.title,
      share_to: null,
    });

    setShareModalOpen(true);
  };

  const [shareErrors, setShareErrors] = useState({});

  //Define the validation schema
  const validationSchemaShareHistory = Yup.object({
    title: Yup.string().required("Document is Required"),
    share_to: Yup.string().required("Share to is Required"),
  });

  //
  const submitShareHistory = async (e) => {
    e.preventDefault();

    try {
      await validationSchemaShareHistory.validate(documentShareData, { abortEarly: false });
      setIsLoading(true);
      const result = await api.postMethod(documentShareData, "user/share-document");
      setIsLoading(false);
      if (result) {
        setShareModalOpen(false);
      }
    } catch (error) {
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setShareErrors(newErrors);
    }
  };

  const handleShareChange = (e) => {
    const { name, value } = e.target;
    setDocumentShareData({ ...documentShareData, [name]: value });

    validateShareFields(name, value);
  };

  const validateShareFields = (name, value) => {
    try {
      validationSchemaShareHistory.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setShareErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setShareErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return `${formattedDate}`;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${formattedTime}`;
  };
  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            <Announcement module={"USER_DOCUMENTS"} />
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Upload & Manage Documents</li>
                      </ol>
                    </div>
                    <h4 className="page-title">Upload & Manage Documents</h4>
                  </div>
                </div>
              </div>
              {/* end page title */}
              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <div className="row justify-content-between">
                        <div className="col-auto">
                          <form className="d-flex flex-wrap align-items-center">
                            <div className="me-3">
                              <label htmlFor="inputPassword2" className="sr-only">
                                Search
                              </label>
                              <input
                                type="search"
                                className="form-control"
                                id="inputPassword2"
                                placeholder="Search by keyword..."
                              />
                            </div>
                          </form>
                        </div>
                        <div className="col-auto">
                          <div className="text-lg-end my-1 my-lg-0">
                            <Link
                              to="/user/compliance-lists"
                              className="btn btn-info waves-effect waves-light mb-2 me-1"
                            >
                              <i className="fas fa-folder-open me-1" />
                              Facility Compliance Lists
                            </Link>

                            <button
                              type="button"
                              className="btn btn-primary waves-effect waves-light mb-2 me-1"
                              onClick={toggleUploadModal}
                            >
                              <i className="fas fa-upload me-1" /> Upload Document
                            </button>
                            <Link
                              to="/user/document-share-history"
                              className="btn btn-info waves-effect waves-light mb-2 me-1"
                            >
                              <i className="fa fa-history me-1" /> Share History
                            </Link>

                            <Link
                              to="/user/document-access-requests"
                              className="btn btn-secondary waves-effect waves-light mb-2"
                            >
                              <i className="fa fa-paper-plane me-1" /> Document Access Requests
                            </Link>
                          </div>
                        </div>
                        {/* end col*/}
                      </div>
                      <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th>Title</th>
                              <th>Type</th>
                              <th>Uploaded On</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              <tr>
                                <td colSpan="4">Loading...</td>
                              </tr>
                            ) : (
                              <>
                                {noRecordsFound ? (
                                  <tr>
                                    <td colSpan="4">No records found</td>
                                  </tr>
                                ) : (
                                  <>
                                    {documents&&Object.entries(documents).map(([key, value]) => (
                                      <tr key={value.id}>
                                        <td>{value.title}</td>
                                        <td>{value.doc_type_name}</td>
                                        <td>
                                          {formatDate(value.created_at)}{" "}
                                          <small className="text-muted">
                                            {formatTime(value.created_at)}
                                          </small>
                                        </td>
                                        <td>
                                          <a
                                            href={value.dir_path}
                                            download
                                            target="_blank"
                                            rel="noreferrer"
                                            className="btn btn-secondary btn-xs me-1"
                                            data-tooltip-id="mt-4"
                                            data-tooltip-content="Download Document"
                                          >
                                            <i className="mdi mdi-download" />
                                          </a>
                                          <Tooltip id="mt-4"></Tooltip>

                                          <button
                                            type="button"
                                            className="btn btn-secondary btn-xs  me-1"
                                            data-tooltip-id="mt-1"
                                            data-tooltip-content="Share Document"
                                            onClick={() => handleShareHistory(value)}
                                          >
                                            <i className="mdi mdi-share" />
                                          </button>
                                          <Tooltip id="mt-1"></Tooltip>
                                          <button
                                            type="button"
                                            className="btn btn-secondary btn-xs  me-1"
                                            data-tooltip-id="mt-3"
                                            data-tooltip-content="Edit Document"
                                            onClick={() => handleEdit(value)}
                                          >
                                            <i className="mdi mdi-pencil" />
                                          </button>
                                          <Tooltip id="mt-3"></Tooltip>
                                          <button
                                            type="button"
                                            className="btn btn-secondary btn-xs  me-1"
                                            data-tooltip-id="mt-2"
                                            data-tooltip-content="Remove Document"
                                            onClick={() => handleDelete(value)}
                                          >
                                            <i className="mdi mdi-delete" />
                                          </button>
                                          <Tooltip id="mt-2"></Tooltip>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {/* 
                      <ul className="pagination pagination-rounded justify-content-end my-2">
                        <li className="page-item">
                          <span className="page-link" aria-label="Previous">
                            <span aria-hidden="true">«</span>
                            <span className="sr-only">Previous</span>
                          </span>
                        </li>
                        <li className="page-item active">
                          <span className="page-link">1</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link">2</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link">3</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link">4</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link">5</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link" aria-label="Next">
                            <span aria-hidden="true">»</span>
                            <span className="sr-only">Next</span>
                          </span>
                        </li>
                      </ul>
                      */}
                    </div>
                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      <Modal show={shareModalOpen} onHide={toggleShareModal} centered>
        <form onSubmit={submitShareHistory}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0">Share Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-2">
                  <label htmlFor="title" className="form-label">
                    Document
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="title"
                    readOnly
                    defaultValue={documentShareData.title !== null && documentShareData.title}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-0">
                  <label htmlFor="share_to" className="form-label">
                    Share To
                  </label>
                  <textarea
                    className="form-control"
                    rows="5"
                    id="share_to"
                    name="share_to"
                    placeholder="Multiple email address must be seperted by comma (,)"
                    onChange={handleShareChange}
                  ></textarea>
                  {shareErrors.share_to && <span className="error">{shareErrors.share_to}</span>}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={toggleShareModal}>
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Share
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={uploadModalOpen} onHide={toggleUploadModal} centered>
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0">Upload New Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 mb-2">
                <label className="form-label" htmlFor="title">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  name="title"
                  onChange={handleChange}
                  defaultValue={formData.title !== null ? formData.title : ""}
                />
                {errors.title && <span className="error">{errors.title}</span>}
              </div>

              <div className="col-md-12 mb-2">
                <label className="form-label" htmlFor="doc_type_id">
                  Type
                </label>
                <select
                  className="form-control"
                  id="doc_type_id"
                  name="doc_type_id"
                  onChange={handleChange}
                  value={formData.doc_type_id !== null ? formData.doc_type_id : ""}
                >
                  <option value="" key="">
                    Please Select
                  </option>
                  {/* Render the states */}
                  {docTypes.map((dtype) => (
                    <option key={dtype.id} value={dtype.id}>
                      {dtype.doc_name}
                    </option>
                  ))}
                </select>
                {errors.doc_type_id && <span className="error">{errors.doc_type_id}</span>}
              </div>

              <div className="col-md-12">
                <label className="form-label" htmlFor="file_name">
                  Document
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="file_name"
                  name="file_name"
                  onChange={handleFileChange}
                />
                {errors.file_name && <span className="error">{errors.file_name}</span>}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={toggleUploadModal}>
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Upload
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default Documents;
