import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Header from "../../../../Layout/Header";
import Footer from "../../../../Layout/Footer";
import Sidebar from "../../../../Layout/Sidebar";
import ThemeSettings from "../../../../Layout/ThemeSettings";
import * as api from "../../../../../services/ApiService";
import * as Yup from "yup";
import * as tokenUtils from "../../../../../utils/tokenUtils";
import Spinner from "../../../../Loader/Spinner";
import PageTitle from "../../../../Layout/PageTitle";
import { currentRole } from "../../../../Layout/HelmetComponent";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";

function Client_Post_Job_Request_Step3() {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { jobID } = useParams();

  const location = useLocation();
  const isStaff = location.pathname.includes("/staff");
  const urlSlug = isStaff ? "staff" : "client";
  const {userAllDetails}= location.state
  console.log(userAllDetails)
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [formData, setformData] = useState({
    step: "step0",
    id: null,
    user_id: user.id,
    token: user.token,
    description: null,
    qualification: null,
    responsibilities: null,
    start_date:null,
    end_date: null,
    total_opening: null,
    show_pay_rate: 1,
    min_work_per_week: null,
  });

// Empty dependency array ensures that the effect runs only once, when the component mounts

  //Define the validation schema
  const validationSchema = Yup.object({
    description: Yup.string().notRequired("Description is Required"),
    qualification: Yup.string().required("Qualification is Required"),
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };



  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/" + urlSlug + "/dashboard" },
    { text: "Job Request", link: "/" + urlSlug + "/job-request" },
    { text: "Send Opportunity", link: null },
  ];

  const handleFormSubmit =async()=>{
    try{
      const payload={
        ...formData,
        unique_id: generateJobId(),
        salary_start_range:userAllDetails.pay_rate,
        salary_type:userAllDetails.pay_type,
        profession_id:userAllDetails.profession_id,
        shift_id:userAllDetails.shift_id,
        specialty_id:userAllDetails.speciality_id,
        title:userAllDetails.title,
        state_id:userAllDetails.state_id,
        city_id:userAllDetails.city_id,
        employment_type_id:userAllDetails.employment_type_id
      }
      const res = await api.postMethod(payload, "client/post-job");
      if(res){

        navigate("/client/job-request")
        toast.success("Job Request Saved Successfully");
      }
    }catch(error){
      console.log(error)
    }
   
  }

  const generateJobId = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const length = 8; // You can adjust the length of the job ID as needed
    let jobId = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      jobId += characters.charAt(randomIndex);
    }

    return jobId;
  };
  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              <PageTitle title="Post a Job" breadcrumbItems={breadcrumbItems} />
              {/*Job progress bar start*/}
              <div className="row mt-2">
                <div className="col-12">
                  <div className="stepper-wrapper">
                    <div className="stepper-item completed">
                      <div className="step-counter">1</div>
                      <div className="step-name">Position Details</div>
                    </div>
                    <div className="stepper-item completed">
                      <div className="step-counter">2</div>
                      <div className="step-name">Location &amp; Salary</div>
                    </div>
                    <div className="stepper-item active">
                      <div className="step-counter">3</div>
                      <div className="step-name">Qualification &amp; Responsibilities</div>
                    </div>

                  </div>
                </div>
              </div>
              {/*Job progress bar end*/}
              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <h4 className="header-title text-uppercase mb-2">
                        Job Qualification &amp; Responsibilities
                      </h4>
                      <p className="sub-header">Specify Job qualification and responsibilities</p>
                      <form
                       onSubmit={handleFormSubmit}
                       >
                      <div className="row">
                          <div className="form-group mb-3 col-md-4">
                            <label htmlFor="start_date">Start Date *</label>
                           <input
                            type="date"
                            name="start_date"
                            id="start_date"
                            onChange={handleChange}
                            className="form-control"
                           />
                           
                          </div>
                          <div className="form-group mb-3 col-md-4">
                            <label htmlFor="end_date">End Date *</label>
                           <input
                            type="date"
                            name="end_date"
                            id="end_date"
                            onChange={handleChange}
                            className="form-control"
                           />
                           
                          </div>

                          <div className="form-group mb-3 col-md-4">
                            <label htmlFor="total_opening">Totat Opening</label>
                            <input
                            type="text"
                            onChange={handleChange}
                            name="total_opening"
                            id="total_opening"
                            className="form-control"
                            placeholder="0"
                           />
                          </div>
                          
                        </div>
                        <div className="row">
                         
                          <div className="form-group mb-3 col-md-4">
                            <label htmlFor="show_pay_rate">Show Pay Rate on Job Listings?</label>
                            <select
                             className="form-control"
                             name="show_pay_rate"
                             onChange={handleChange} >
                              <option>Please Select</option>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                          </div>
                          <div className="form-group mb-3 col-md-4">
                            <label htmlFor="min_work_per_week">Minimum hours per Week </label>
                           <input
                            type="text"
                            name="min_work_per_week"
                            id="min_work_per_week"
                            className="form-control"
                            onChange={handleChange}
                           />
                           
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group mb-3 col-md-12">
                            <label htmlFor="description">Description</label>
                            <textarea
                              name="description"
                              id="description"
                              className="form-control editor"
                            //   value={formData.description || ""}
                              onChange={handleChange}
                              rows="5"
                            />
                            {/* {errors.description && (
                              <span className="error">{errors.description}</span>
                            )} */}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group mb-3 col-md-12">
                            <label htmlFor="qualification">Qualification</label>
                            <textarea
                              name="qualification"
                              id="qualification"
                              className="form-control editor"
                            //   value={formData.qualification || ""}
                              onChange={handleChange}
                              rows="5"
                            />
                            {errors.qualification && (
                              <span className="error">{errors.qualification}</span>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group mb-3 col-md-12">
                            <label htmlFor="responsibilities">Responsibilities</label>
                            <textarea
                              name="responsibilities"
                              id="Responsibilities"
                              className="form-control editor"
                            //   value={formData.responsibilities || ""}
                              onChange={handleChange}
                              rows="5"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <Link
                            //   to={`/` + urlSlug + `/job-post-step2/` + jobID}
                              className="btn btn-secondary"
                            >
                              <i className="fas fa-angle-left" /> Back
                            </Link>
                          </div>
                          <div className="col-md-6">
                            <div className="text-lg-end">
                              <Button onClick={()=>handleFormSubmit()} className="btn btn-primary">
                                Save and Continue <i className="fas fa-angle-right" />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default Client_Post_Job_Request_Step3;
