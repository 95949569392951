import React, { useEffect, useRef, useState } from "react";
import * as api from "../../../services/ApiService";
import { Button } from "react-bootstrap";
import * as tokenUtils from "../../../utils/tokenUtils";
import Spinner from "../../Loader/Spinner";
import html2pdf from "html2pdf.js";
import { toast } from "react-toastify";
const SubmissionFileDownload = () => {
  const [data, setData] = useState();
  const pdfRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  let user = tokenUtils.getTokenData();

  const getSubmissiondata = async () => {
    try {
      const payload = user.unique_id;
      const apiData = await api.getMethod(`submission-file/${payload}`);
      if (apiData) {
        setData({ __html: apiData.data });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    getSubmissiondata();
  }, []);

  const handleSubmissionFileDownload = async () => {
    const element = document.getElementById("submissionDocument");
    element.style.fontSize = "15px";

    const options = {
      margin: [23, 5,32, 5], // Top, Left, Bottom, Right margins
      filename: "download.pdf",
      image: { type: "jpeg", quality:1 },
      jsPDF: { unit: "pt", format: "a3", orientation: "portrait" },
    };

   await html2pdf()
      .set(options)
      .from(element)
      .outputPdf("blob")
      .then((pdfBlob) => {
        console.log(pdfBlob)
        const pdfFile = new File([pdfBlob], "submission.pdf", { type: "application/pdf" });

        if (pdfFile && pdfFile) {
          setIsLoading(true)
          console.log("object,",pdfFile )
          return api.postFileMethod(
            { user_id: user.id, submission_file: pdfFile },
            "upload-submission-file",
          );
        }
      })
      .then((response) => {
        console.log("API Response:", response);
        toast.success("Your Submission File Saved Successfully");
        setIsLoading(false)
      })
      .catch((error) => {
        console.error("Error generating or uploading PDF:", error);
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const handlePrint = () => {
    const printContent = document.getElementById("submissionDocument");
    const originalContent = document.body.innerHTML;

    // Replace the entire body with the printable content
    document.body.innerHTML = printContent.innerHTML;

    // Trigger print
    window.print();

    // Restore the original content after printing
    document.body.innerHTML = originalContent;
    window.location.reload(); // Reload the page to reinitialize the React app
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          overflowX: "hidden",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "1vw",
        }}
      >
        <div
          ref={pdfRef}
          style={{ width: "100%", fontSize: 15 }}
          id="submissionDocument"
          dangerouslySetInnerHTML={data}
        />
        <div>
          {isLoading === false ? (
            <>
              <Button
                style={{ marginRight: "2vw", padding: "0.5vw 2vw" }}
                className="btn btn-danger"
                onClick={() => handlePrint()}
              >
                Print
              </Button>
              <Button
                style={{ padding: "0.5vw 2vw" }}
                className="btn btn-success"
                onClick={() => handleSubmissionFileDownload()}
              >
                Save
              </Button>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      {isLoading === true && <Spinner />}
    </>
  );
};

export default SubmissionFileDownload;
