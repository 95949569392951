import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import * as api from "../../../services/ApiService";
import * as Yup from "yup";
import * as tokenUtils from "../../../utils/tokenUtils";
import Spinner from "../../Loader/Spinner";
import PageTitle from "../../Layout/PageTitle";
import { currentRole } from "../../Layout/HelmetComponent";

function JobPostStep3() {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { jobID } = useParams();

  const location = useLocation();
  const isStaff = location.pathname.includes("/staff");
  const urlSlug = isStaff ? "staff" : "client";

  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [formData, setformData] = useState({
    step: "step3",
    id: null,
    user_id: user.id,
    token: user.token,
    description: null,
    qualification: null,
    responsibilities: null,
  });

  const fetchDraftJobs = async () => {
    try {
      setIsLoading(true);
      const param = {
        step: "step3",
        user_id: user.id,
        token: user.token,
        job_id: localStorage.getItem("job_id"),
        jobID: jobID,
      };
      const apiData = await api.postMethod(param, "client/get-draft-job");
      //const apiDataObj = Object.setPrototypeOf(apiData, Object.prototype);
      if (apiData.status) {
        /*
        // Save the data in local storage
        localStorage.setItem("job_id", apiData.data.id);
        navigate("/client/job-post-step2");
        */
        setformData(apiData.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        navigate("/" + urlSlug + "/job-post-step1");
      }
      /*
      const apiDataObj = apiData.reduce((acc, currentValue, index) => {
        acc[index] = currentValue;
        return acc;
      }, {});
      */

      //setWorkHisstories(apiDataObj);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDraftJobs();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  //Define the validation schema
  const validationSchema = Yup.object({
    description: Yup.string().notRequired("Description is Required"),
    qualification: Yup.string().required("Qualification is Required"),
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setIsLoading(true);
      // Normalize formData before sending it to the API
      const normalizedFormData = { ...formData };
      Object.keys(normalizedFormData).forEach((key) => {
        if (normalizedFormData[key] === "") {
          normalizedFormData[key] = null;  // Convert empty strings to null
        }
      });
      const result = await api.postMethod(formData, "client/post-job");
      if (result) {
        setIsLoading(false);
        if (jobID) navigate("/" + urlSlug + "/job-post-step5/" + jobID, {
          state: { unique_id: formData.unique_id },
        });
        else navigate("/" + urlSlug + "/job-post-step5/"+{
          state: { unique_id: formData.unique_id },
        });
      }
    } catch (error) {
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/" + urlSlug + "/dashboard" },
    { text: "Jobs", link: "/" + urlSlug + "/job-postings" },
    { text: "Post a Job", link: null },
  ];

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              <PageTitle title="Post a Job" breadcrumbItems={breadcrumbItems} />
              {/*Job progress bar start*/}
              <div className="row">
                <div className="col-12">
                  <div className="stepper-wrapper">
                    <div className="stepper-item completed">
                      <div className="step-counter">1</div>
                      <div className="step-name">Position Details</div>
                    </div>
                    <div className="stepper-item completed">
                      <div className="step-counter">2</div>
                      <div className="step-name">Location &amp; Salary</div>
                    </div>
                    <div className="stepper-item active">
                      <div className="step-counter">3</div>
                      <div className="step-name">Qualification &amp; Responsibilities</div>
                    </div>
                    {/* <div className="stepper-item">
                      <div className="step-counter">4</div>
                      <div className="step-name">Attatchments</div>
                    </div> */}
                  </div>
                </div>
              </div>
              {/*Job progress bar end*/}
              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <h4 className="header-title text-uppercase mb-2">
                        Job Qualification &amp; Responsibilities
                      </h4>
                      <p className="sub-header">Specify Job qualification and responsibilities</p>
                      <form onSubmit={handleFormSubmit}>
                        <div className="row">
                          <div className="form-group mb-3 col-md-12">
                            <label htmlFor="description">Description</label>
                            <textarea
                              name="description"
                              id="description"
                              className="form-control editor"
                              value={formData.description || ""}
                              onChange={handleChange}
                              rows="5"
                            />
                            {errors.description && (
                              <span className="error">{errors.description}</span>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group mb-3 col-md-12">
                            <label htmlFor="qualification">Qualification</label>
                            <textarea
                              name="qualification"
                              id="qualification"
                              className="form-control editor"
                              value={formData.qualification || ""}
                              onChange={handleChange}
                              rows="5"
                            />
                            {errors.qualification && (
                              <span className="error">{errors.qualification}</span>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group mb-3 col-md-12">
                            <label htmlFor="responsibilities">Responsibilities</label>
                            <textarea
                              name="responsibilities"
                              id="Responsibilities"
                              className="form-control editor"
                              value={formData.responsibilities || ""}
                              onChange={handleChange}
                              rows="5"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <Link
                              to={`/` + urlSlug + `/job-post-step2/` + jobID}
                              className="btn btn-secondary"
                            >
                              <i className="fas fa-angle-left" /> Back
                            </Link>
                          </div>
                          <div className="col-md-6">
                            <div className="text-lg-end">
                              <button type="submit" className="btn btn-primary">
                                Save and Continue <i className="fas fa-angle-right" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default JobPostStep3;
