import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Header from "../../../../Layout/Header";
import Footer from "../../../../Layout/Footer";
import Sidebar from "../../../../Layout/Sidebar";
import ThemeSettings from "../../../../Layout/ThemeSettings";
import * as api from "../../../../../services/ApiService";
import * as Yup from "yup";
import * as tokenUtils from "../../../../../utils/tokenUtils";
import Spinner from "../../../../Loader/Spinner";
import PageTitle from "../../../../Layout/PageTitle";
import { currentRole } from "../../../../Layout/HelmetComponent";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { formatAmount } from "../../../../../utils/AmountFormatter";
function Client_Post_Job_Request_Step2() {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { jobID } = useParams();
const Navigate = useNavigate()
  const location = useLocation();
  const {UserData} = location.state
  const urlSlug =  "client";
console.log(location.state.send_job_opportunity_id)
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [formData, setformData] = useState({
    client_id: user.id,
    user_id:location.state.userId,
    // token: user.token,
    state_id: null,
    city_id: null,
    pay_rate: null,
    // salary_end_range: null,
    pay_type: null,
    // show_pay_rate: 1,
    employment_type_id: null,
    job_opportunity_id:location.state.jobOppertunity
    // min_work_per_week: null,
  });
// Empty dependency array ensures that the effect runs only once, when the component mounts

  //Define the validation schema
  const validationSchema = Yup.object({
    state_id: Yup.string().required("State is Required"),
    city_id: Yup.string().required("City is Required"),
  });

  const [errors, setErrors] = useState({});
  const [openStateModal, setOpenStateModal] = useState(false);
  const [userAllDetails,setuserAllDetails] = useState()
  const toggleStateModal = () => {
    setOpenStateModal(!openStateModal);

    if (!openStateModal) {
      // Reset validation errors
      // setErrors({});
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setIsLoading(true);
      const payload ={
        city_id:formData.city_id,
        client_id:formData.client_id,
        employment_type_id:formData.employment_type_id,
        job_opportunity_id:location.state.jobOppertunity,
        pay_rate:formData.pay_rate,
        pay_type:formData.pay_type,
        profession_id:formData.profession_id,
        shift_id:formData.shift_id,
        speciality_id:formData.speciality_id,
        start_date:formData.start_date,
        state_id:formData.state_id,
        title:formData.title
      }
      const result = await api.postMethod(payload, "client/job-requests/create-job-opportunity");
      if (result) {
        setIsLoading(false);
        if(!location.state.send_job_opportunity_id){
          sendOppertunity(result.data)
        }
        setuserAllDetails(result.data)
      }
    } catch (error) {
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const [states, setStates] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-states");
        setStates(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [cities, setCities] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchCityData = async () => {
      try {
        const apiData = await api.getMethod("get-cities/" + formData.state_id);

        setCities(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchCityData();
  }, [formData.state_id]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-employment-types");
        setEmploymentTypes(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/" + urlSlug + "/dashboard" },
    { text: "Job Request", link: "/" + urlSlug + "/job-request" },
    { text: "Send Opportunity", link: null },
  ];
  const sendOppertunity = async(value)=>{
    try{
      const payload ={
        client_id:user.id,
        user_id:location.state.userId,
        job_request_id:location.state.jobRequest,
        job_opportunity_id: value.id

      }
      console.log(payload)
      const apiData = await api.postMethod(payload,"client/job-requests/send-job-opportunity");
      if(apiData){
        toggleStateModal()
        toast.success("Opportunity Send Successfully")
        // console.log(apiData.data)
      }
    }
    catch(error){
      // console.log(error)
    }
  }
  const getjobdetailsData = async()=>{
    try{
      const payload={
        client_id:user.id,
        job_opportunity_id:location.state.jobOppertunity,
      }
      const apiData = await api.postMethod(payload,
        "client/job-requests/create-job-opportunity"
      );
      toast.success("Data saved Successfully")
        setformData(apiData.data)
    }
    catch(error){
      console.log("error",error)
    }
  }
  useEffect(()=>{
    if(location.state.jobOppertunity)
    getjobdetailsData()
  },[location.state.jobOppertunity])
  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              <PageTitle title="Send an Opportunity" breadcrumbItems={breadcrumbItems} />
              {/*Job progress bar start*/}
              <div className="row mt-2">
                <div  style={{marginBottom:"1vw",display:"flex",alignItems:"center",justifyContent:"space-between"}}  className="col-12">
                  
                  <div style={{marginLeft:"1vw",borderRadius:"10%"}} className="custom-box-shadow p-3">
                <p className="mb-0"><strong>Name</strong>: {UserData.user_name}</p>
                <p className="mb-0"><strong>Profession</strong>: {UserData.profession}</p>
                <p className="mb-0"><strong>Shift</strong>: {UserData.shift_title}</p>
                <p className="mb-0"><strong>Specialty</strong>: {UserData.specialty}</p>
                <p className="mb-0"><strong>Desired Salary</strong>:  ${formatAmount(UserData.pay_rate) || "N/A"}{UserData.pay_type || "N/A"}</p>
                <p className="mb-0"><strong>Open to Traveling to</strong>:{UserData &&
                UserData?.state_city_data.map((data) => (
                 
                    <p className='mb-0'>
                      <strong>{data.state}</strong> |{" "}
                      {data && data.cities.map((city) => <span> {city},</span>)}
                    </p>
                  
                ))}</p>
                
              </div>
              <div style={{width:"75%",marginTop:0}} className="stepper-wrapper" >
                    <div  className="stepper-item completed" >
                      <div className="step-counter">1</div>
                      <div className="step-name">Position Details</div>
                    </div>
                    <div className="stepper-item active">
                      <div className="step-counter">2</div>
                      <div className="step-name">Location &amp; Salary</div>
                    </div>
                  </div>
                </div>
              </div>
              {/*Job progress bar end*/}
              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <h4 className="header-title text-uppercase mb-2">Job Location and Salary</h4>
                      <p className="sub-header">Specify Job location and salary details</p>
                      <form onSubmit={handleFormSubmit}>
                        <div className="row">
                          <div className="form-group mb-3 col-md-6">
                            <label htmlFor="state_id">State *</label>
                            <select
                              id="state_id"
                              className="form-control"
                              name="state_id"
                              value={formData.state_id !== null ? formData.state_id : ""}
                              onChange={handleChange}
                            >
                              <option value="" key="">
                                Please Select
                              </option>
                              {/* Render the professions */}
                              {states.map((state) => (
                                <option key={state.id} value={state.id}>
                                  {state.name}
                                </option>
                              ))}
                            </select>
                            {errors.state_id && <span className="error">{errors.state_id}</span>}
                          </div>
                          <div className="form-group mb-3 col-md-6">
                            <label htmlFor="city_id">City *</label>
                            <select
                              id="city_id"
                              className="form-control"
                              name="city_id"
                              value={formData.city_id !== null ? formData.city_id : ""}
                              onChange={handleChange}
                            >
                              <option value="" key="">
                                Please Select
                              </option>
                              {/* Render the professions */}
                              {cities.map((city) => (
                                <option key={city.id} value={city.id}>
                                  {city.city_name}
                                </option>
                              ))}
                            </select>
                            {errors.city_id && <span className="error">{errors.city_id}</span>}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group mb-3 col-md-4">
                            <label htmlFor="pay_rate">Pay Rate</label>
                            <div className="row">
                              <div className="col-12">
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                      $
                                    </span>
                                  </div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="pay_rate"
                                    name="pay_rate"
                                    defaultValue={formData.pay_rate}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-3 col-md-4">
                            <label htmlFor="pay_type">Pay Type</label>
                            <select
                              id="pay_type"
                              className="form-control"
                              name="pay_type"
                              value={formData.pay_type !== null ? formData.pay_type : ""}
                              onChange={handleChange}
                            >
                              <option value="" key="">
                                Please select
                              </option>
                              <option value="Hourly" key="Hourly">
                                Hourly
                              </option>
                              <option value="Weekly" key="Weekly">
                                Weekly
                              </option>
                              <option value="Monthly" key="Monthly">
                                Monthly
                              </option>
                              <option value="Yearly" key="Yearly">
                                Yearly
                              </option>
                            </select>
                          </div>
                          <div className="form-group mb-3 col-md-4">
                            <label htmlFor="employment_type_id">Emploment Type</label>
                            <select
                              id="employment_type_id"
                              className="form-control"
                              name="employment_type_id"
                              value={
                                formData.employment_type_id !== null
                                  ? formData.employment_type_id
                                  : ""
                              }
                              onChange={handleChange}
                            >
                              <option value="" key="">
                                Please select
                              </option>
                              {/* Render the professions */}
                              {employmentTypes.map((emp) => (
                                <option key={emp.id} value={emp.id}>
                                  {emp.title}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                       

                        <div className="row">
                          <div className="col-md-6">
                            <Button
                            onClick={()=>navigate("/client/sent_job_request",{state:{job_opportunity_id:location.state.id}})}
                              to={`/client/sent_job_request`}
                              className="btn btn-secondary"
                              type="submit"
                            >
                              <i className="fas fa-angle-left" /> Back
                            </Button>
                          </div>
                          <div className="col-md-6">
                            <div className="text-lg-end">
                            {!location.state.send_job_opportunity_id?<button className="btn btn-primary" type="submit">
                                Send Opportunity <i className="fas fa-angle-right" />
                              </button>:<Button className="btn" style={{background:"green",border:"none"}} onClick={()=> navigate(`/client/job-request/${UserData.id}`)}>Finish</Button>
                              
                            }
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ThemeSettings />
      {isLoading === true && <Spinner />}
      <Modal
        show={openStateModal}
        onHide={toggleStateModal}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Send Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 ">
            New Opportunity has been sent. Monitor
 your Job Requests for updates. Would you
 like to post this job?
            </div>
          </div>
          <div className="d-flex" style={{alignItems:"center",justifyContent:"center"}}>
          <Button className="mr-4" style={{marginRight:"2vw"}} onClick={()=>navigate("/client/sent_job_request3",{state:{userAllDetails:userAllDetails&&userAllDetails}})}>
            Yes
          </Button>
          <Button  onClick={()=>navigate(`/client/job-request/${UserData.id}`)}>
            No
          </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Client_Post_Job_Request_Step2;
