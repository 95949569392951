import React from 'react'
import { formatAmount } from '../../../../../utils/AmountFormatter'

const Job_Request_details = ({userDetail}) => {
  return<>
    <div>
        <div>
            <strong>Profession</strong> : {userDetail?.profession}
            <strong style={{marginLeft:"10vw"}}> Desired Salary</strong> : ${formatAmount(userDetail?.pay_rate)} {userDetail?.pay_type}
        </div>
        <div>
            <strong>Desired Shift</strong> : {userDetail?.shift_title}
        </div>
        <div>
            <strong>Start Date</strong> : {userDetail?.start_date}
        </div>
        <div>
            <strong>Open to Traveling to</strong> : {userDetail &&
                userDetail?.state_city_data.map((data) => (
                 
                    <p className='mb-0'>
                      <strong>{data.state}</strong> |{" "}
                      {data && data.cities.map((city) => <span> {city},</span>)}
                    </p>
                  
                ))}
        </div>
    </div>
  </>
}

export default Job_Request_details
