import React, { useState, useEffect } from "react";
import * as api from "../../../../../services/ApiService";
import * as tokenUtils from "../../../../../utils/tokenUtils";

function EducationInfo({ profileInfo, formatDate }) {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [workHistories, setWorkHisstories] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchWorkHistoryData = async () => {
    try {
      const param = {
        user_id: profileInfo.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "user/get-educational-info");
      //const apiDataObj = Object.setPrototypeOf(apiData, Object.prototype);

      /*
      const apiDataObj = apiData.reduce((acc, currentValue, index) => {
        acc[index] = currentValue;
        return acc;
      }, {});
      */

      setWorkHisstories(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkHistoryData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  return (
    <>
      <div className="col-md-12">
        <div className="table-responsive">
          <table className="table table-centered table-nowrap mb-0">
            <thead className="table-light">
              <tr>
                <th>Degree</th>
                <th>School/College</th>
                <th align="center" className="text-center">
                Graduated
                </th>
                <th align="center" className="text-center">
                Location
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="3">Loading...</td>
                </tr>
              ) : (
                <>
                  {noRecordsFound ? (
                    <tr>
                      <td colSpan="3">No records found</td>
                    </tr>
                  ) : (
                    <>
                      {Object.entries(workHistories).map(([key, value]) => (
                        <tr key={value.id}>
                          <td>
                            {value.degree}
                            
                          </td>
                          <td>
                            {value.school_college}
                            
                          </td>
                          <td align="center">
                          {value.currently_attending === "1"
                            ? "Present"
                            : value.end_month + "/" + value.end_year}
                          </td>
                          <td align="center">
                          {value.location}
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default EducationInfo;
