import React, { useState, useEffect } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import JobPreference from "./Tabs/JobPreference";
import Reference from "./Tabs/Reference";
import PersonalInfo from "./Tabs/PersonalInfo";
import WorkHistory from "./Tabs/WorkHistory";
import EducationalInfo from "./Tabs/EducationalInfo";
import Skills from "./Tabs/Skills";
import { Link, useLocation } from "react-router-dom";
import * as api from "../../../services/ApiService";
import * as tokenUtils from "../../../utils/tokenUtils";

function Profile({ refreshPage }) {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState("job_preference");

  useEffect(() => {
    if (location.state?.tab) {
      setSelectedTab(location.state.tab);
    }
  }, [location.state]);

  const [skills, setSkills] = useState([]);
  const [states, setStates] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [shifts, setShifts] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-skills");
        setSkills(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-states");
        setStates(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-employment-types");
        setEmploymentTypes(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-shifts");
        setShifts(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-professions");
        setProfessions(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  //console.log("skills", skills);

  // Tab select event
  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  const fetchProfileData = async () => {
    try {
      const param = {
        user_id: user.id,
      };
      const apiData = await api.postMethod(param, "user/get-profile-data");

      setOverallProgress({
        ...overallProgress,
        class: calProgressClass(apiData.data.overall_progress),
        value: apiData.data.overall_progress,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [overallProgress, setOverallProgress] = useState({ class: "", value: 0 });
  const calProgressClass = (percentage) => {
    // Success for percentages 61 and above
    if (percentage >= 61) return "bg-success";
  
    // Warning for percentages between 30 and 60
    if (percentage >= 30) return "bg-warning";
  
    // Danger for percentages below 30
    return "bg-danger";
  };


  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">My Profile</li>
                      </ol>
                    </div>
                    <h4 className="page-title">My Profile</h4>
                  </div>
                </div>
              </div>
              {/* end page title */}
              <div className="row">
                <div className="col-lg-12 col-xl-12">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="header-title mb-2">
                        Profile Strength : <strong>{overallProgress.value}%</strong>
                      </h5>
                      <div className="progress mb-1">
                        <div
                          className={`progress-bar ${overallProgress.class}`}
                          role="progressbar"
                          style={{ width: `${overallProgress.value}%` }}
                          aria-valuenow={overallProgress.value}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                      <p className="sub-header mb-0">
                        Allow us to walk you through each step so you can create a comprehensive
                        profile and get hired for your future position.
                      </p>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb=3">
                        <div className="col">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "job_preference" ? "btn-primary" : "btn-light")
                            }
                            onClick={() => handleTabSelect("job_preference")}
                          >
                             Professional Details
                          </button>
                        </div>
                        <div className="col">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "personal_info" ? "btn-primary" : "btn-light")
                            }
                            onClick={() => handleTabSelect("personal_info")}
                          >
                            Personal Info
                          </button>
                        </div>
                        <div className="col">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "work_history" ? "btn-primary" : "btn-light")
                            }
                            onClick={() => handleTabSelect("work_history")}
                          >
                            Work History
                          </button>
                        </div>
                        <div className="col">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "educational_info" ? "btn-primary" : "btn-light")
                            }
                            onClick={() => handleTabSelect("educational_info")}
                          >
                            Educational Info
                          </button>
                        </div>
                        <div className="col">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "references" ? "btn-primary" : "btn-light")
                            }
                            onClick={() => handleTabSelect("references")}
                          >
                            References
                          </button>
                        </div>

                        {/* 
                        <div className="col">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "skills" ? "btn-primary" : "btn-light")
                            }
                            onClick={() => handleTabSelect("skills")}
                          >
                            Skills
                          </button>
                        </div>
                        */}
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-body">
                      {selectedTab === "job_preference" && (
                        <JobPreference
                          states={states}
                          employmentTypes={employmentTypes}
                          jobShifts={shifts}
                          fetchProfileData={fetchProfileData}
                          refreshPage={refreshPage}
                        />
                      )}
                      {selectedTab === "personal_info" && (
                        <PersonalInfo
                          states={states}
                          fetchProfileData={fetchProfileData}
                          refreshPage={refreshPage}
                        />
                      )}
                      {selectedTab === "work_history" && (
                        <WorkHistory
                          states={states}
                          professions={professions}
                          employmentTypes={employmentTypes}
                          fetchProfileData={fetchProfileData}
                          refreshPage={refreshPage}
                        />
                      )}
                      {selectedTab === "educational_info" && (
                        <EducationalInfo
                          fetchProfileData={fetchProfileData}
                          refreshPage={refreshPage}
                        />
                      )}
                      {selectedTab === "skills" && (
                        <Skills skills={skills} fetchProfileData={fetchProfileData} />
                      )}
                      {selectedTab === "references" && (
                        <Reference fetchProfileData={fetchProfileData} refreshPage={refreshPage} />
                      )}
                    </div>
                  </div>

                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      <ThemeSettings />
    </>
  );
}

export default Profile;
