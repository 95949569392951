import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import Header from "../../../Layout/Header";
import Footer from "../../../Layout/Footer";
import Sidebar from "../../../Layout/Sidebar";
import ThemeSettings from "../../../Layout/ThemeSettings";
import * as api from "../../../../services/ApiService";
import * as Yup from "yup";
import * as tokenUtils from "../../../../utils/tokenUtils";
import * as toast from "../../../../utils/toastUtils";
import PageTitle from "../../../Layout/PageTitle";
import PasswordToggle from "../../../../hooks/PasswordToggle";
import { phoneRegExp } from "../../../../utils/dateFormat";
import { Button } from "react-bootstrap";
import { format } from "date-fns";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Tooltip } from "react-tooltip";

function AddJobRequestStep1() {
  let navigate = useNavigate();
  const user = tokenUtils.getTokenData();
const location = useLocation()
const [professions,setProfessions] = useState()
const [specialties,setspecialties] = useState()

  const [errors,setErrors] = useState({})

  const [formData, setformData] = useState({
profession_id:null
  });

  const validationSchema = Yup.object().shape({

    shift_id: Yup.string().required("Select at least one shift"),
    profession_id: Yup.string().required("Profession is Required"),
    speciality_id: Yup.string().required("Specialty is Required"),
    start_date: formData.fexiable_start_date||formData.start_date!==null ?"":Yup.string().required("Start Date is Required"),
    // end_date: new Date(formData.end_date) >= new Date(formData.start_date) ?Yup.string().required("End Date is Invalid 1"):Yup.string().required("End Date is Invalid 2") 
   
    // end_date: Yup.string().required("End Date is Required"),
  });

  const handleChange = (e, date) => {
    if (date == "startDate") {
      setformData({ ...formData, start_date: format(e, "MM-dd-yyyy") });
    } else if (date == "endDate") {
      setformData({ ...formData, end_date: format(e, "MM-dd-yyyy") });
    } else {
      const { name, value } = e.target;
      setformData({ ...formData, [name]: value });

      // validateFields(name, value);
    }

  };
  function convertDate(inputDate) {
    let dob = inputDate && moment(inputDate).format("MM/DD/YYYY");
    if (dob == "Invalid Date") {
      if (inputDate.includes("-")) {
        return (dob = new Date(inputDate.split("-").reverse().join("/")));
      } else if (inputDate.includes("/")) {
        const [day, month, year] = inputDate.split("/");
        const dateString = [month, day, year].join("/");
        return (dob = new Date(dateString));
      }
    } else {
      if (typeof new Date(inputDate) === "object") {
        return (dob = new Date(inputDate));
      } else {
        return (dob = inputDate);
      }
    }
    return dob;
  }
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-professions");
        setProfessions(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, [])

    // Function to fetch data from the API
    useEffect(() => {
      // Function to fetch data from the API
      const fetchSpecialtyData = async () => {
        try {
          const apiData = await api.getMethod(
            "get-specialties/" + formData.profession_id
          );
  
          setspecialties(apiData.data);
        } catch (error) {
          console.log(error)
          // console.error("Error fetching data:", error);
        }
      };
  
      // Call the fetchData function when the component mounts
      fetchSpecialtyData();
    }, [formData.profession_id]); 


const handleSaveData =async()=>{
  let isValid = true;
  try {
    await validationSchema.validate(formData, { abortEarly: false });
  } catch (error) {
    isValid = false;
    const newErrors = {};
    if (error.inner && Array.isArray(error.inner)) {
      error.inner.forEach((element) => {
        newErrors[element.path] = element.message;
      });
    } else {
      console.error("Unexpected error structure:", error);
    }
    setErrors(newErrors);
  }

  if (!isValid) return;
try{
 const payload={
user_id:user.id,
start_date:formData.start_date,
end_date:formData.end_date,
profession_id:formData.profession_id,
speciality_id:formData.speciality_id,
shift_id:formData.shift_id,
job_request_id:formData.id
  }
  const apiData = await api.postMethod(payload,
    "user/job-requests/store"
  );
  if(apiData){
    toast.success("Data Sucessfully Saved");
    navigate("/user/job-request-step2",{state:{isUpdate:"update",id:apiData.data.id}})
  
  }
}
catch(error){
  console.error("Error updating data:", error);
}
}

const handleUpdateData=()=>{
  console.log("update working")
}
const getjobdetailsData = async()=>{
  try{
    const payload={
      user_id:user.id,
      job_request_id:location.state.id,
    }
    const apiData = await api.postMethod(payload,
      "user/job-requests/get-active-request"
    );
      setformData(apiData.data.job_requests[0])
  }
  catch(error){
    console.log("error",error)
  }
}
console.log(formData)
useEffect(()=>{
  if(location.state){

    getjobdetailsData()
  }
},[location.state])

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <Link to="/user/jobrequest" className="breadcrumb-item active">Job Request</Link>
                      </ol>
                    </div>
                    <h4 className="page-title">Job Oppertunity</h4>
                  </div>
                </div>
              </div>
              {/* <PageTitle title="Add Candidate" breadcrumbItems={breadcrumbItems} /> */}

              {/*Job progress bar start*/}
              <div className="row mt-2">
                <div className="col-12">
                  <div className="stepper-wrapper">
                    <div className="stepper-item active">
                      <div className="step-counter">1</div>
                      <div className="step-name">Position Details</div>
                    </div>
                    <div className="stepper-item">
                      <div className="step-counter">2</div>
                      <div className="step-name">Location & Salary</div>
                    </div>
                    {/* 
                    <div className="stepper-item">
                      <div className="step-counter">3</div>
                      <div className="step-name">Professional Details</div>
                    </div>
                    */}
                    <div className="stepper-item">
                      <div className="step-counter">3</div>
                      <div className="step-name">Attach Resume</div>
                    </div>
                    {/* 
                    <div className="stepper-item">
                      <div className="step-counter">5</div>
                      <div className="step-name">Skills</div>
                    </div>
                    */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <h4 className="header-title text-uppercase mb-2">
                        Desired Position Details
                      </h4>
                      <p className="sub-header">
                        List your desired position details below so that
                        Recruiters can match you to these exact details.
                      </p>
                      <form>
                        <div className="row">
                          <div className="form-group mb-3 col-md-3">
                            <label htmlFor="profession_id">Profession *</label>
                            <select
                              type="text"
                              className="form-control"
                              id="profession"
                              name="profession_id"
                              onChange={handleChange}
                              value={formData.profession_id}
                            > 
                              <option value="">Please Select</option>
                            {professions&&professions.map((profession) => (
                <option key={profession.id} value={profession.id}>
                  {profession.profession}
                </option>
              ))}

                            </select>
                            {errors.profession_id && (
                <span className="error">{errors.profession_id}</span>
              )}
                          </div>

                          <div className="form-group mb-3 col-md-3">
                            <label htmlFor="speciality_id">Specialty</label>
                            <select
                              type="text"
                              className="form-control"
                              id="speciality_id"
                              name="speciality_id"
                              onChange={handleChange}
                              value={formData.speciality_id}
                              readOnly
                            >
                              <option value="">Please Select</option>

                              {specialties&&specialties.map((specialty) => (
                <option key={specialty.id} value={specialty.id}>
                  {specialty.specialty}
                </option>
              ))}
                            </select>
                            {errors.speciality_id && (
                <span className="error">{errors.speciality_id}</span>
              )}
                          </div>
                          <div className="form-group mb-3 col-md-3">
                            <label
                              htmlFor="start_date"
                            >
                              Start Date
                            </label>
                            <label 
                              style={{ marginRight: "5vw" }}
                            className="float-end">
                    <input
                      type="checkbox"
                      name="fexiable_start_date"
                      value="1"
                      data-tooltip-id="tt-1"
                      data-tooltip-content="fexiable_start_date"
                      onChange={handleChange}
                      defaultChecked={formData.fexiable_start_date}
                    />
                    <Tooltip id="tt-1"></Tooltip>
                  </label>
                            <DatePicker
                              type="date"
                              className="form-control"
                              id="start_date"
                              required
                              name="start_date"
                              onChange={(e) => handleChange(e, "startDate")}
                              dateFormat="MM/dd/yyyy"
                              placeholderText="MM/DD/YYYY"
                              value={convertDate(formData?.start_date)}
                              selected={
                                formData?.start_date &&
                                convertDate(formData?.start_date)
                              }
                              // defaultValue={formData.school_college}
                            />
               {formData.fexiable_start_date||formData.start_date!==null ?"":errors.start_date && ( <span className="error">{errors.start_date}</span>)}
                          </div>
                          <div className="form-group mb-3 col-md-3">
                            <label
                              style={{ marginRight: "3vw" }}
                              htmlFor="end_date"
                            >
                              End Date
                            </label>
                            <DatePicker
                              type="date"
                              className="form-control"
                              id="end_date"
                              required
                              name="end_date"
                              onChange={(e) => handleChange(e, "endDate")}
                              dateFormat="MM/dd/yyyy"
                              placeholderText="MM/DD/YYYY"
                              value={convertDate(formData?.end_date)}
                              selected={
                                formData?.end_date &&
                                convertDate(formData?.end_date)
                              }
                              // defaultValue={formData.school_college}
                            />
                            {/* {errors.end_date && (
                <span className="error">{errors.end_date}</span>
              )} */}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group mb-3 col-md-3">
                            <label htmlFor="shift_id">Shift *</label>
                            <select
                              type="text"
                              className="form-control"
                              id="shift_id"
                              name="shift_id"
                              onChange={handleChange}
                              value={formData.shift_id}
                            >
                            <option value="">Please Select</option>
                            <option value="1">Day</option>
                            <option value="2">Evening</option>
                            <option value="3">Night</option>
                            </select>
                            {errors.shift_id && (
                <span className="error">{errors.shift_id}</span>
              )}
                          </div>
                        </div>
                      </form>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        margin: "2vw",
                      }}
                    >
                      <Button
                        onClick={() =>{handleSaveData()}}
                                                                    >
                        Save and Continue <i className="fas fa-angle-right" />
                      </Button>
                    </div>
                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
              {/*Job progress bar end*/}

              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>
      <ThemeSettings />
    </>
  );
}

export default AddJobRequestStep1;
