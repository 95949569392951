import React, { useEffect, useState } from 'react'
import * as api from "../../../services/ApiService";
import * as tokenUtils from "../../../utils/tokenUtils";
import { Link, useParams } from 'react-router-dom';
import ThemeSettings from '../../Layout/ThemeSettings';
import Footer from '../../Layout/Footer';
import Sidebar from '../../Layout/Sidebar';
import Header from '../../Layout/Header';
import moment from 'moment/moment';
import { formatAmount } from '../../../utils/AmountFormatter';
const JobRequestDetails = () => {
    const [getJobOppertunityDetails,setGetJobOppertunityDetails] = useState()
    const { id } = useParams();
    let user = tokenUtils.getTokenData();
    const getDetails =async()=>{
        try{
            const payload ={
                user_id:user.id,
                job_opportunity_id:id
            }
const apidata = await api.postMethod(payload,"user/job-requests/get-job-opportunity-details")
if(apidata){
    setGetJobOppertunityDetails(apidata.data)
    console.log(apidata)
}
        }
        catch(error){
            console.log(error)
        }
    }

    useEffect(()=>{
        getDetails()
    },[])
  return<>
   <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
            
            <div className="row">
                <div className="col-12 mb-2">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <Link to="/user/jobrequest" className="breadcrumb-item active">Job Request</Link>
                        <li className="breadcrumb-item">Job Opportunity Details</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              {/* start page title */}
              {/* <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">All Subscription</li>
                      </ol>
                    </div>
                    <h4 className="page-title">All Subscription</h4>
                  </div>
                </div>
              </div> */}
              {/* end page title */}
              <div className="card">
                        <div className="card-header border-bottom">
                          <h5 className="card-title mb-0 fw-semibold"><h4>Job Opportunity Details</h4></h5>
                        </div>
                        <div className="card-body">

                          <div className="card p-2">
        <div>
            <strong>Profession</strong> : {getJobOppertunityDetails?.profession}
            <strong style={{marginLeft:"10vw"}}> Desired Salary</strong> : ${formatAmount(getJobOppertunityDetails?.pay_rate)} {getJobOppertunityDetails?.pay_type}
        </div>
        <div>
            <strong>Desired Shift</strong> : {getJobOppertunityDetails?.shift_title}
        </div>
        <div>
            <strong>Start Date</strong> : {getJobOppertunityDetails?.start_date}
        </div>
        <div>
            <strong>Open to Traveling to</strong> : <strong>{getJobOppertunityDetails?.state_name}</strong> |{" "}
            {getJobOppertunityDetails && getJobOppertunityDetails?.city_name}
        </div>
    </div>

                          {/* {jobRow.company_state_code && jobRow.company_city_name && (
                            <div className="mb-0">
                              <h5>Location</h5>
                              <p className="mb-0">
                                {jobRow.company_state_code !== null &&
                                jobRow.company_city_name !== null
                                  ? jobRow.company_city_name + ", " + jobRow.company_state_code
                                  : jobRow.company_state_code !== null
                                  ? jobRow.company_state_code
                                  : jobRow.company_city_name !== null
                                  ? jobRow.company_city_name
                                  : ""}
                              </p>
                            </div>
                          )} */}
                        </div>
 
                      </div>
{
  getJobOppertunityDetails?.company_name? <div className="card">
                        <div className="card-header border-bottom">
                          <h5 className="card-title mb-0 fw-semibold">About Company</h5>
                        </div>
                        <div className="card-body">
                          <div className="media mb-3">
                            {getJobOppertunityDetails?.company_profile_pic && (
                              <img
                                className="d-flex mr-3 rounded-circle avatar-md float-start me-2"
                                src={getJobOppertunityDetails?.company_profile_pic}
                                alt={getJobOppertunityDetails?.company_name}
                              />
                            )}
                            <div className="media-body">
                              <h4 className="mt-0 mb-5">{getJobOppertunityDetails?.company_name}</h4>
                              <p className="text-muted">
                                {/* <strong>Member Since</strong> :{" "} */}
                                {/* {moment(getJobOppertunityDetails.founded_in).format("MM-DD-YYYY")} */}
                              </p>
                            </div>
                          </div>

                          {getJobOppertunityDetails?.bio && (
                            <div className="mb-0">
                              <h5>About {getJobOppertunityDetails?.company_name}</h5>
                              <p >{getJobOppertunityDetails?.bio}</p>
                            </div>
                          )}

                          {getJobOppertunityDetails?.founded_in && (
                            <div className="mb-0">
                              <h5>Founded In</h5>
                              <p>{getJobOppertunityDetails?.founded_in}</p>
                            </div>
                          )}

                          {getJobOppertunityDetails?.primary_industry && (
                            <div className="mb-0">
                              <h5>Primary Industry</h5>
                              <p>{getJobOppertunityDetails?.primary_industry}</p>
                            </div>
                          )}

                          {getJobOppertunityDetails?.company_size && (
                            <div className="mb-0">
                              <h5>Company Size</h5>
                              <p>{getJobOppertunityDetails?.company_size}</p>
                            </div>
                          )}

                          {/* {jobRow.company_state_code && jobRow.company_city_name && (
                            <div className="mb-0">
                              <h5>Location</h5>
                              <p className="mb-0">
                                {jobRow.company_state_code !== null &&
                                jobRow.company_city_name !== null
                                  ? jobRow.company_city_name + ", " + jobRow.company_state_code
                                  : jobRow.company_state_code !== null
                                  ? jobRow.company_state_code
                                  : jobRow.company_city_name !== null
                                  ? jobRow.company_city_name
                                  : ""}
                              </p>
                            </div>
                          )} */}
                        </div>
                        <div className="card-footer text-center border-top">
                          <Link
                            to={"/user/company-profile/" + getJobOppertunityDetails?.company_id}
                            className="btn btn-primary"
                          >
                            View Full Details
                          </Link>
                        </div>
                      </div>:""
}
             
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      <ThemeSettings />

  </>
}

export default JobRequestDetails
