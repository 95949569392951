import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import * as profile from "../../../../services/ProfileService";
import * as Yup from "yup";
import { Tooltip } from "react-tooltip";
import * as api from "../../../../services/ApiService";
import * as tokenUtils from "../../../../utils/tokenUtils";
import useConfirm from "../../../../hooks/useConfirm";

function EducationalInfo({ fetchProfileData, refreshPage }) {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);

    if (!modalOpen) {
      setformData({
        id: null,
        user_id: user.id,
        token: user.token,
        degree: null,
        school_college: null,
        end_month: null,
        end_year: null,
        currently_attending: null,
        location: null,
      });

      // Reset validation errors
      setErrors({});
    }
  };

  const [formData, setformData] = useState({
    user_id: user.id,
    token: user.token,
    degree: null,
    school_college: null,
    end_month: null,
    end_year: null,
    currently_attending: null,
    location: null,
  });

  const [errors, setErrors] = useState({});

  const handleEdit = (record) => {
    // Populate formData with the values of the selected record
    setformData({
      user_id: user.id,
      token: user.token,
      id: record.id,
      degree: record.degree,
      school_college: record.school_college,
      end_month: record.end_month,
      end_year: record.end_year,
      currently_attending: record.currently_attending,
      location: record.location,
    });

    setModalOpen(true);
  };

  const { confirm } = useConfirm();
  const handleDelete = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      record.user_id = user.id;
      record.token = user.token;

      var result = await profile.deleteEducationalInfo(record, "user/delete-educational-info");
      if (result) {
        fetchEducationalInfoData();
        fetchProfileData();
      }
    }
  };

  const validationSchema = Yup.object({
    degree: Yup.string().required("Degree is Required"),
    school_college: Yup.string().required("School/College is Required"),
    
    // currently_attending: Yup.boolean(),
    end_month: formData.currently_attending||formData.end_year !== null?"":Yup.string().required("End Month/Year is Required")
    // end_year: Yup.string().when("currently_attending", {
    //   is: "0",
    //   then: Yup.string().required("End Year is Required"),
    // }),
    // location: Yup.string().required("Location is Required"),
    
  });

  const [educationalInfo, setEducationalInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchEducationalInfoData = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "user/get-educational-info");
      //const apiDataObj = Object.setPrototypeOf(apiData, Object.prototype);

      /*
      const apiDataObj = apiData.reduce((acc, currentValue, index) => {
        acc[index] = currentValue;
        return acc;
      }, {});
      */
if(apiData){

  setEducationalInfo(apiData.data);
  setLoading(false);
}
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEducationalInfoData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const result = await profile.updateWorkHistory(formData, "user/update-educational-info");
      if (result) {
        setModalOpen(false);
        refreshPage();
        fetchProfileData();
        fetchEducationalInfoData();
      }

    } catch (error) {
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  // Generate an array of years
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year >= currentYear - 15; year--) {
    years.push(year);
  }
  return (
    <>
      <div className="table-responsive">
        <table className="table table-centered table-nowrap mb-0">
          <thead className="table-light">
            <tr>
              <th>Degree</th>
              <th>School/College</th>
              <th>Graduated</th>
              <th>Location</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="5">Loading...</td>
              </tr>
            ) : (
              <>
                {noRecordsFound ? (
                  <tr>
                    <td colSpan="5">No records found</td>
                  </tr>
                ) : (
                  <>
                    {educationalInfo&&Object.entries(educationalInfo).map(([key, value]) => (
                      <tr key={value.id}>
                        <td>{value.degree}</td>
                        <td>{value.school_college}</td>
                        <td>
                          {value.currently_attending === "1"
                            ? "Present"
                            : value.end_month + "/" + value.end_year}
                        </td>
                        <td>{value.location}</td>
                        <td>
                          <span className="action-icon" onClick={() => handleEdit(value)}>
                            <i className="mdi mdi-pencil" />
                          </span>
                          <span className="action-icon" onClick={() => handleDelete(value)}>
                            <i className="mdi mdi-delete" />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <button type="submit" className="btn btn-info float-start mt-2" onClick={toggleModal}>
        <i className="fa fa-plus"></i> Add Education
      </button>
      {/* 
      <ul className="pagination pagination-rounded justify-content-end my-2">
        <li className="page-item">
          <a className="page-link" href="javascript: void(0);" aria-label="Previous">
            <span aria-hidden="true">«</span>
            <span className="sr-only">Previous</span>
          </a>
        </li>
        <li className="page-item active">
          <a className="page-link" href="javascript: void(0);">
            1
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="javascript: void(0);">
            2
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="javascript: void(0);">
            3
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="javascript: void(0);">
            4
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="javascript: void(0);">
            5
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="javascript: void(0);" aria-label="Next">
            <span aria-hidden="true">»</span>
            <span className="sr-only">Next</span>
          </a>
        </li>
      </ul>
*/}

      <Modal show={modalOpen} onHide={toggleModal} centered backdrop="static">
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0">Add Educational Info</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-2">
                  <label htmlFor="degree" className="form-label">
                    Degree
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="degree"
                    name="degree"
                    required
                    placeholder=""
                    onChange={handleChange}
                    defaultValue={formData.degree}
                  />
                  {errors.degree && <span className="error">{errors.degree}</span>}
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-2">
                  <label htmlFor="school_college" className="form-label">
                    School/College
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="school_college"
                    name="school_college"
                    onChange={handleChange}
                    defaultValue={formData.school_college}
                  />
                  {errors.school_college && <span className="error">{errors.school_college}</span>}
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-2">
                  <label htmlFor="end_month" className="form-label">
                    End Month
                  </label>
                  <select
                    className="form-control"
                    id="end_month"
                    name="end_month"
                    onChange={handleChange}
                    value={formData.end_month !== null ? formData.end_month : ""}
                  >
                    <option value="" key="">
                      -
                    </option>
                    {Array.from(Array(12), (e, i) => {
                      return (
                        <option value={i + 1} key={i + 1}>
                          {i + 1}
                        </option>
                        
                      );
                    })}
                  </select>
                  {errors.end_month && <span className="error">{errors.end_month}</span>}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-2">
                  <label htmlFor="end_year" className="form-label">
                    End Year
                  </label>
                  <label className="float-end">
                    <input
                      type="checkbox"
                      name="currently_attending"
                      defaultValue="1"
                      data-tooltip-id="tt-1"
                      data-tooltip-content="Currently Attending"
                      onChange={handleChange}
                      defaultChecked={formData.currently_attending}
                    />
                    <Tooltip id="tt-1"></Tooltip>
                  </label>
                  <select
                    className="form-control"
                    id="end_year"
                    name="end_year"
                    onChange={handleChange}
                    value={formData.end_year !== null ? formData.end_year : ""}
                  >
                    <option value="" key="">
                      -
                    </option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-md-12">
                <div className="mb-2">
                  <label htmlFor="location" className="form-label">
                    Location <small>(City, State, Country)</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="location"
                    required
                    name="location"
                    onChange={handleChange}
                    defaultValue={formData.location}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={toggleModal}>
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default EducationalInfo;