import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import Header from "../../../Layout/Header";
import Footer from "../../../Layout/Footer";
import Sidebar from "../../../Layout/Sidebar";
import ThemeSettings from "../../../Layout/ThemeSettings";
import * as api from "../../../../services/ApiService";
import * as Yup from "yup";
import * as tokenUtils from "../../../../utils/tokenUtils";
import * as toast from "../../../../utils/toastUtils";
import PageTitle from "../../../Layout/PageTitle";
import PasswordToggle from "../../../../hooks/PasswordToggle";
import { phoneRegExp } from "../../../../utils/dateFormat";
import { Button, Modal } from "react-bootstrap";

function JobRequestStep2() {
  let navigate = useNavigate();
  const location = useLocation();
  // const id= location.state.id
  // const update=location.state.isUpdate
  const user = tokenUtils.getTokenData();

  const breadcrumbItems = [
    { text: "Admin Dashboard", link: "/sudent/dashboard" },
    { text: "Candidates", link: "/sudent/dashboard" },
    // { text: "Add Candidate", link: null },
  ];

  const [formData, setformData] = useState({
    state: null,
  });
  const handleChange = (e, date) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    // validateFields(name, value);
  };
  const [states, setStates] = useState([]);
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-states");
        setStates(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchCityData = async () => {
      try {
        const apiData = await api.getMethod("get-cities/" + formData.state);

        setCities(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchCityData();
  }, [formData.state]);

  const validationSchema = Yup.object().shape({

    pay_rate: Yup.string().required("Set Your Pay Amount"),
    pay_type: Yup.string().required("Pay Type is Required"),
    employment_type_id: Yup.string().required("Employment Type is Required"),
    // start_date: formData.fexiable_start_date||formData.start_date!==null ?"":Yup.string().required("Start Date is Required"),
    // end_date: Yup.string().required("End Date is Required"),
  });


  const [employmentTypes, setEmploymentTypes] = useState();

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-employment-types");
        setEmploymentTypes(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []);
  const [errors,setErrors] = useState({})

  const handleSaveData = async () => {
    let isValid = true;
    try {
      await validationSchema.validate(formData, { abortEarly: false });
    } catch (error) {
      isValid = false;
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  
    if (!isValid) return;
    try {
      const payload = {
        user_id: user.id,
        // states: formData.state,
        // cities: formData.city,
        job_request_id:location.state.id,
        pay_rate: formData.pay_rate,
        pay_type: formData.pay_type,
        employment_type_id: formData.employment_type_id,
      };
      const apiData = await api.postMethod(payload, "user/job-requests/store");
      if(apiData){
        navigate("/user/job-request-step3",{state:{isUpdate:"update",id:apiData.data.id}});

      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const getjobdetailsData = async () => {
    try {
      const payload = {
        user_id: user.id,
        job_request_id:location.state.id,
      };
      const apiData = await api.postMethod(
        payload,
        "user/job-requests/get-active-request"
      );
      setformData(apiData.data.job_requests[0]);
      setStateCityData(apiData.data.job_requests[0].state_city_data)
    } catch (error) {
      console.log("error", error);
    }
  };
  console.log(formData);
  useEffect(() => {
    if (location.state.id !== null) {
      getjobdetailsData();
    }
  }, [location.state.id]);

  const [openStateModal, setOpenStateModal] = useState(false);
  const toggleStateModal = () => {
    setOpenStateModal(!openStateModal);

    if (!openStateModal) {
      // Reset validation errors
      // setErrors({});
    }
  };
  const [selectState, setSelectState] = useState([]);
  const handleSelectChange = (e) => {
    const { name, value, checked } = e.target;

    const options = Array.from(
      e.target.selectedOptions,
      (option) => +option.value
    );

    // Remove "all" if user deselects states individually
    const filteredOptions = options.filter((item) => item !== "all");

    setSelectState({ ...selectState, desired_state_ids: filteredOptions });

    // Uncheck Select All if not all states are selected
    if (filteredOptions.length == states.length) {
      // setSelectAll(true);
      setSelectState({
        ...selectState,
        desired_state_ids: [...filteredOptions, "all"],
      });
    }
    //  else setSelectAll(false);
    // validateFields(name, filteredOptions);
  };
  const [stateCityData,setStateCityData]= useState()
const handleStateSubmit= async()=>{
  try{
   const payload={
      state_id:formData.state,
      city_id:selectState.desired_state_ids,
      job_request_id:location.state.id,
      user_id:user.id,
    }
    const apiData = await api.postMethod(
      payload,
      "user/job-requests/store-state-city"
    );
    if(apiData){
      console.log(apiData)
      toggleStateModal()
      setStateCityData(apiData.data)
    }
  }catch(error){
    console.log(error)
  }
}


const handleGoBack=()=>{
  navigate("/user/job-request-step1",{state:{isUpdate:"update",id:location.state.id}})
}
  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <Link to="/user/jobrequest" className="breadcrumb-item active">Job Request</Link>
                      </ol>
                    </div>
                    <h4 className="page-title">Job Oppertunity</h4>
                  </div>
                </div>
              </div>
              {/* <PageTitle title="Add Candidate" breadcrumbItems={breadcrumbItems} /> */}

              {/*Job progress bar start*/}
              <div className="row mt-2">
                <div className="col-12">
                  <div className="stepper-wrapper">
                    <div className="stepper-item completed">
                      <div className="step-counter">1</div>
                      <div className="step-name">Position Details</div>
                    </div>
                    <div className="stepper-item">
                      <div className="step-counter">2</div>
                      <div className="step-name">Location & Salary</div>
                    </div>
                    {/* 
                    <div className="stepper-item">
                      <div className="step-counter">3</div>
                      <div className="step-name">Professional Details</div>
                    </div>
                    */}
                    <div className="stepper-item">
                      <div className="step-counter">3</div>
                      <div className="step-name">Attach Resume</div>
                    </div>
                    {/* 
                    <div className="stepper-item">
                      <div className="step-counter">5</div>
                      <div className="step-name">Skills</div>
                    </div>
                    */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <h4 className="header-title text-uppercase mb-2">
                        {" "}
                        Desired Location & Salary
                      </h4>
                      <p className="sub-header">
                        List your desired position location and salary below.
                        Recruiters will only offer you positions around this
                        salary range.
                      </p>
                      <form>
                        {/* <div className="row">
                          <div className="form-group mb-3 col-md-6">
                            <label htmlFor="state">State *</label>
                            
                          </div>

                          <div className="form-group mb-3 col-md-6">
                            <label htmlFor="city">City *</label>
                           
                          </div>
                          </div> */}
                        <div className="row">
                          <div className="form-group mb-3 col-md-4">
                            <label htmlFor="salary_range">Pay Rate</label>
                            <div className="row">
                              <div className="col-12">
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text"
                                      id="inputGroupPrepend"
                                    >
                                      $
                                    </span>
                                  </div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="pay_rate"
                                    name="pay_rate"
                                    value={formData.pay_rate}
                                    // defaultValue={formData.salary_start_range}
                                    onChange={handleChange}
                                  />
                                   {errors.pay_rate && (
                <span className="error">{errors.pay_rate}</span>
              )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-3 col-md-4">
                            <label htmlFor="pay_type">Pay Type</label>
                            <select
                              className="form-control"
                              id="pay_type"
                              name="pay_type"
                              value={formData.pay_type}
                              onChange={handleChange}
                            >
                            <option value="">Please Select</option>
                              <option value="Weekly">Weekly</option>
                              <option value="Monthly">Monthly</option>
                              <option value="Daily">Daily</option>
                            </select>
                            {errors.pay_type && (
                <span className="error">{errors.pay_type}</span>
              )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group mb-3 col-md-4">
                            <label htmlFor="emp_type">Employment Type *</label>
                            <select
                              type="text"
                              className="form-control"
                              id="employment_type_id"
                              name="employment_type_id"
                              value={formData.employment_type_id}
                              onChange={handleChange}
                            >
                              <option>Please Select</option>
                              {employmentTypes &&
                                employmentTypes.map((employmentTypes) => (
                                  <option
                                    key={employmentTypes.id}
                                    value={employmentTypes.id}
                                  >
                                    {employmentTypes.title}
                                  </option>
                                ))}
                            </select>
                            {errors.employment_type_id && (
                <span className="error">{errors.employment_type_id}</span>
              )}
                          </div>
                          <div className="form-group mb-3 col-md-4 mt-3">
                            <Button className="mb-1" onClick={() => toggleStateModal()}>State / City</Button>
                            <div>
                              {stateCityData&&stateCityData.map((data)=>(
                                <>
                                  <p>{data.state} |  {data&&data.cities.map((city)=>(<span> {city},</span>))}</p>
                                </>
                              ))}
                            </div>
                          </div>
                        </div>
                      </form>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          onClick={() => handleGoBack()}
                        >
                          Back
                        </Button>
                        <Button
                          onClick={() => {
                            handleSaveData();
                          }}
                        >
                          Save and Continue <i className="fas fa-angle-right" />
                        </Button>
                      </div>
                    </div>
                    {/* end card-body*/}
                  </div>

                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
              {/*Job progress bar end*/}

              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>
      <ThemeSettings />
      <Modal
        show={openStateModal}
        onHide={toggleStateModal}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Add RTO</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 ">
              <div className="mb-2">
                <label htmlFor="start_date" className="form-label">
                  State
                </label>
                <div>
                  <select
                    type="text"
                    className="form-control"
                    id="state"
                    name="state"
                    onChange={handleChange}
                  >
                    <option value="">Please Select</option>
                    {states.map((state) => (
                      <option key={state.id} value={state.id}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="mb-2">
                <label htmlFor="end_date" className="form-label">
                  City
                </label>
                <br />
                <small className="text-muted">
                  (Ctrl+Click to Select Multiple)
                </small>
                <div>
                  <select
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    onChange={handleSelectChange}
                    readOnly
                    multiple
                  >
                    <option>Please Select</option>
                    {cities.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.city_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-12"></div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-secondary" onClick={toggleStateModal}>
            Close
          </Button>
          <Button
            className="btn btn-primary"
             onClick={()=>handleStateSubmit()}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default JobRequestStep2;
